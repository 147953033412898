import React from "react";
import ReactModal from "react-modal"
import "./login.css"

const ThankYouModal = ({ isOpen, onClose }: any) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Modal"
      className="custom-modal"
      overlayClassName="custom-overlay"
    >
      <div
        id="w-__popup_default__"
        className="popup-center"
        style={{
          padding: "0px",
          outline: "0px",
          font: "inherit",
          border: "0px",
          boxSizing: "border-box",
          WebkitFontSmoothing: "antialiased",
          inset: "0px",
          margin: "auto",
          position: "fixed",
          width: "400px",
          height: "250px",
          zIndex: 1000001,
        }}
      >
        <div
          className="popup-wrapper p-absolute full-width full-height"
          style={{
            margin: "0px",
            padding: "0px",
            outline: "0px",
            font: "inherit",
            border: "0px",
            boxSizing: "border-box",
            WebkitFontSmoothing: "antialiased",
            width: "100%",
            position: "absolute",
            top: "0px",
            left: "0px",
            height: "250px",
          }}
        >
          <div
            className="popup-background p-absolute full-width full-height"
            style={{
              margin: "0px",
              padding: "0px",
              outline: "0px",
              font: "inherit",
              border: "0px",
              boxSizing: "border-box",
              WebkitFontSmoothing: "antialiased",
              height: "100%",
              width: "100%",
              position: "absolute",
              overflow: "hidden",
              background: "rgb(255, 255, 255)",
              borderColor: "rgba(76, 175, 80, 0.8)",
              borderRadius: "13px",
              borderStyle: "solid",
              boxShadow: "rgba(139, 195, 74, 0.2) 4px 4px 9px 0px",
              color: "rgb(69, 64, 64)",
            }}
          />
          <div
            id="w-oc2dcqis"
            className="com-text-block p-absolute"
            style={{
              margin: "0px",
              padding: "0px",
              outline: "0px",
              font: "inherit",
              border: "0px",
              boxSizing: "border-box",
              WebkitFontSmoothing: "antialiased",
              position: "absolute",
              top: "21px",
              left: "36px",
              width: "176px",
              height: "54px",
            }}
          >
            <div
              className="text-block"
              style={{
                margin: "0px",
                padding: "0px",
                outline: "0px",
                font: "inherit",
                border: "0px",
                boxSizing: "border-box",
                WebkitFontSmoothing: "antialiased",
              }}
            >
              <p
                className="text-block-css full-width"
                style={{
                  margin: "0px",
                  padding: "0px",
                  outline: "0px",
                  font: "inherit",
                  border: "0px",
                  boxSizing: "border-box",
                  WebkitFontSmoothing: "antialiased",
                  width: "max-content",
                  display: "inline-block",
                  wordBreak: "break-word",
                  color: "rgb(69, 64, 64)",
                  fontFamily: "Roboto, sans-serif",
                  fontSize: "36px",
                  fontWeight: "bold",
                  textAlign: "start",
                  backgroundClip: "text",

                }}
              >
                Thank you
              </p>
            </div>
          </div>
          <div
            id="w-pn1igdhr"
            className="com-text-block p-absolute"
            style={{
              margin: "0px",
              padding: "0px",
              outline: "0px",
              font: "inherit",
              border: "0px",
              boxSizing: "border-box",
              WebkitFontSmoothing: "antialiased",
              position: "absolute",
              top: "89px",
              left: "36px",
              width: "328px",
              height: "48px",
            }}
          >
            <div
              className="text-block"
              style={{
                margin: "0px",
                padding: "0px",
                outline: "0px",
                font: "inherit",
                border: "0px",
                boxSizing: "border-box",
                WebkitFontSmoothing: "antialiased",
              }}
            >
              <p
                className="text-block-css full-width"
                style={{
                  margin: "0px",
                  padding: "0px",
                  outline: "0px",
                  font: "inherit",
                  border: "0px",
                  boxSizing: "border-box",
                  WebkitFontSmoothing: "antialiased",
                  width: "100%",
                  display: "inline-block",
                  wordBreak: "break-word",
                  fontFamily: "Roboto, sans-serif",
                  fontSize: "16px",
                  textAlign: "start",
                  backgroundClip: "text",
                }}
              >
                Your submission has been received! Keep an eye on your phone or
                email because we will contact you soon.
              </p>
            </div>
          </div>
          <div
            id="w-au0o7yag"
            className="com-button p-absolute cursor-pointer"
            style={{
              margin: "0px",
              padding: "0px",
              outline: "0px",
              font: "inherit",
              border: "0px",
              boxSizing: "border-box",
              WebkitFontSmoothing: "antialiased",
              position: "absolute",
              transition: "transform 200ms ease-out 0s",
              cursor: "pointer",
              userSelect: "none",
              top: "184px",
              left: "241.5px",
              width: "130px",
              height: "40px",
            }}
          >
            <div
              className="button-css full-height full-width"
              style={{
                margin: "0px",
                padding: "0px",
                outline: "0px",
                font: "inherit",
                border: "0px",
                boxSizing: "border-box",
                WebkitFontSmoothing: "antialiased",
                height: "100%",
                width: "100%",
                display: "-webkit-flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                background: "rgba(76, 175, 80, 0.8)",
                borderRadius: "6px",
                borderStyle: "solid",
                color: "rgb(255, 255, 255)",
                fontWeight: "bold",
              }}
            >
              <span
                className="button-loader"
                style={{
                  margin: "0px",
                  padding: "0px",
                  outline: "0px",
                  font: "inherit",
                  border: "0px",
                  boxSizing: "border-box",
                  WebkitFontSmoothing: "antialiased",
                  borderRadius: "50%",
                  animation:
                    "1.8s ease-in-out 0s infinite normal none running bblFadInOut",
                  width: "1.3em",
                  height: "1.3em",
                  color: "rgb(255, 255, 255)",
                  fontSize: "7px",
                  position: "relative",
                  textIndent: "-9999em",
                  transform: "translateZ(0px)",
                  animationDelay: "-0.16s",
                  display: "none",
                  top: "-17px",
                }}
              />
              <div
                className="button-text full-width u-select-none"
                style={{
                  margin: "0px",
                  padding: "0px",
                  outline: "0px",
                  font: "inherit",
                  border: "0px",
                  boxSizing: "border-box",
                  WebkitFontSmoothing: "antialiased",
                  width: "100%",
                  userSelect: "none",
                  display: "table-cell",
                  verticalAlign: "middle",
                  textAlign: "unset",
                  lineHeight: 1.6,
                }}
                onClick={onClose}
              >
                Close
              </div>
            </div>
          </div>
          <div
            id="w-au0o7yap"
            className="p-absolute cursor-pointer"
            style={{
              margin: "0px",
              padding: "0px",
              outline: "0px",
              font: "inherit",
              border: "0px",
              boxSizing: "border-box",
              WebkitFontSmoothing: "antialiased",
              cursor: "pointer",
              position: "absolute",
              top: "0px",
              left: "370px",
              zIndex: 1,
              width: "29px",
              height: "29px",
            }}
          >
            <div
              className="rectangle-css full-mask-size mask-position full-height full-width"
              style={{
                margin: "0px",
                padding: "0px",
                outline: "0px",
                font: "inherit",
                border: "0px",
                boxSizing: "border-box",
                WebkitFontSmoothing: "antialiased",
                height: "100%",
                width: "100%",
                maskSize: "100% 100%",
                maskPosition: "0% 0%",
                borderColor: "rgb(229, 231, 235)",
                borderStyle: "solid",
                background: "rgb(212, 212, 212)",
                maskImage:
                  'url("data:image/svg+xml;base64,PHN2ZyBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSdub25lJyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMDAlJyBoZWlnaHQ9JzEwMCUnIHZpZXdCb3g9JzAgMCAyNCAyNCc+IDxwYXRoIGQ9J00xMy40NiwxMkwxOSwxNy41NFYxOUgxNy41NEwxMiwxMy40Nkw2LjQ2LDE5SDVWMTcuNTRMMTAuNTQsMTJMNSw2LjQ2VjVINi40NkwxMiwxMC41NEwxNy41NCw1SDE5VjYuNDZMMTMuNDYsMTJaJz48L3BhdGg+IDwvc3ZnPg==")',
                zIndex: 1,
              }}
            />
          </div>
        </div>
      </div>

    </ReactModal>
  );
};

export default ThankYouModal;