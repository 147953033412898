// src/utils/metaPixel.ts
import ReactPixel from "react-facebook-pixel";

export const initMetaPixel = (pixelId: string): void => {
  ReactPixel.init(pixelId); // Initialize Meta Pixel
};

export const trackPageView = (): void => {
  ReactPixel.pageView(); // Track page views
};

export const trackEvent = (
  eventName: string,
  eventParams?: Record<string, any>
): void => {
  ReactPixel.track(eventName, eventParams); // Track custom events
};
