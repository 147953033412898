import React, { useEffect } from "react";
import "../styles.css";
import { AppProps } from "../App";

function Index({ lang }: AppProps) {
  // useEffect(() => {
  //   // Dynamically load external JS (if necessary)
  //   const script = document.createElement("script");
  //   script.src = "./index-supplier/address_wc/address.84.min.js"; // Make sure to put your external JS file in the public directory
  //   script.async = true;
  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `<html lang="vi">
    <head>
        <meta charset="UTF-8" />
        <title></title>
        <meta name="keywords" content="" />
        <meta name="description" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <script async="" src="https://a.pancake.vn/js/app.js?vsn=d"></script>
        <script type="text/javascript">
            !(function (e, t, i) {
                var a,
                    n = e.innerWidth;
                if (((e[i] = true || n < 768 ? "mobile" : "desktop"), "desktop" == e[i])) a = "width=" + 960 + ", user-scalable=no, initial-scale=1.0";
                else {
                    var o = 1;
                    w = 420;
                    w != n && (o = n / w), (a = "width=" + w + ", user-scalable=no, initial-scale=" + o + ", minimum-scale=" + o + ", maximum-scale=" + o);
                }
                var l = t.createElement("meta");
                (l.name = "viewport"), l.setAttribute("content", a), t.head.appendChild(l);
            })(window, document, "DISPLAY");
        </script>
        <meta name="viewport" content="width=420, user-scalable=no, initial-scale=0.9285714285714286, minimum-scale=0.9285714285714286, maximum-scale=0.9285714285714286" />

        <link rel="canonical" href="https://www.claimpinettworkk.site/" />
        <meta property="og:url" content="https://www.claimpinettworkk.site/" />

        <meta property="og:title" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="" />
        <meta property="og:description" content="" />
        <meta name="format-detection" content="telephone=no" />
        <link rel="icon" href="" sizes="16x16" />
        <link rel="dns-prefetch" href="https://statics.pancake.vn" />
        <link rel="dns-prefetch" href="https://content.pancake.vn" />
        <link rel="dns-prefetch" href="https://www.youtube.com" />
        <link rel="dns-prefetch" href="https://a.pancake.vn" />
        <link rel="preconnect" href="https://fonts.gstatic.com/" crossorigin="" />
        <link rel="preconnect" href="https://fonts.googleapis.com/" crossorigin="" />
        <link rel="preconnect" href="https://analytics.tiktok.com/i18n/pixel" />
        <link rel="preconnect" href="https://analytics.tiktok.com/api/v2/pixel" />

        <style type="text/css">
            * {
                margin: 0;
                padding: 0;
                box-sizing: border-box;
                outline: 0;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font: inherit;
                border: 0;
            }
            html {
                font-size: 12px;
                line-height: 1.5;
                -webkit-tap-highlight-color: transparent;
                font-family: "Roboto", sans-serif;
                font-feature-settings: "tnum";
            }
            body {
                background-color: #fff;
                font-size: 12px;
                color: #000;
            }
            .pageview {
                overflow: hidden;
            }
            .lazy {
                background: none !important;
            }
            ol,
            ul {
                list-style: none;
            }
            b {
                font-weight: bold;
            }
            .visibility-hidden {
                visibility: hidden !important;
            }
            .cursor-pointer {
                cursor: pointer;
            }
            .full-height {
                height: 100%;
            }
            .full-width {
                width: 100%;
            }
            .full-mask-size {
                -webkit-mask-size: 100% 100%;
            }
            .mask-position {
                -webkit-mask-position: 0% 0%;
            }
            .u-select-none {
                user-select: none;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
            }
            .p-absolute {
                position: absolute;
            }
            .p-relative {
                position: relative;
            }
            .gallery-view-icon-next img,
            .gallery-view-icon-prev img,
            .carousel-icon-next img,
            .carousel-icon-prev img {
                width: 25px;
                height: 25px;
                filter: drop-shadow(0 0 1px black);
            }
            .icon-play-video {
                position: absolute;
                width: 60px;
                height: 60px;
                top: calc(50% - 30px);
                left: calc(50% - 30px);
                cursor: pointer;
                background-position: center center;
                background-size: cover;
                background-repeat: no-repeat;
                background-image: url(https://content.pancake.vn/1.1/30/5a/c2/93/9ec72c8ce8898050035dddbb989c950bc1faabdc1fdcc11175dea467.svg);
            }
            .player-btn-unmute {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                cursor: pointer;
            }
            .player-btn-unmute > div {
                content: "";
                position: absolute;
                width: 60px;
                height: 60px;
                top: calc(50% - 30px);
                left: calc(50% - 30px);
                background-size: 90%;
                background-color: rgba(0, 0, 0, 0.5);
                border-radius: 100%;
                background-repeat: no-repeat;
                background-position: center center;
                background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2036%2036%22%20width%3D%22100%25%22%20height%3D%22100%25%22%20fill%3D%22%23fff%22%3E%3Cpath%20d%3D%22m%2021.48%2C17.98%20c%200%2C-1.77%20-1.02%2C-3.29%20-2.5%2C-4.03%20v%202.21%20l%202.45%2C2.45%20c%20.03%2C-0.2%20.05%2C-0.41%20.05%2C-0.63%20z%20m%202.5%2C0%20c%200%2C.94%20-0.2%2C1.82%20-0.54%2C2.64%20l%201.51%2C1.51%20c%20.66%2C-1.24%201.03%2C-2.65%201.03%2C-4.15%200%2C-4.28%20-2.99%2C-7.86%20-7%2C-8.76%20v%202.05%20c%202.89%2C.86%205%2C3.54%205%2C6.71%20z%20M%209.25%2C8.98%20l%20-1.27%2C1.26%204.72%2C4.73%20H%207.98%20v%206%20H%2011.98%20l%205%2C5%20v%20-6.73%20l%204.25%2C4.25%20c%20-0.67%2C.52%20-1.42%2C.93%20-2.25%2C1.18%20v%202.06%20c%201.38%2C-0.31%202.63%2C-0.95%203.69%2C-1.81%20l%202.04%2C2.05%201.27%2C-1.27%20-9%2C-9%20-7.72%2C-7.72%20z%20m%207.72%2C.99%20-2.09%2C2.08%202.09%2C2.09%20V%209.98%20z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
            }
            .d-none {
                display: none;
            }
            a:link,
            a:visited {
                color: inherit;
            }
            a {
                text-decoration: none;
            }
            .section-container {
                margin: auto;
            }
            .image-block-css {
                overflow: hidden;
            }
            .image-background,
            .image-background-compare {
                margin: 0 auto;
            }
            .image-background-compare {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            .image-compare-line {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 40;
                width: 38px;
                height: 38px;
                margin-top: -22px;
                margin-left: -22px;
                cursor: pointer;
                border: 3px solid #fff;
                border-radius: 1000px;
                -webkit-box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
                box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
            }
            .image-compare-left-arrow,
            .image-compare-right-arrow {
                top: 50%;
                margin-top: -6px;
                position: absolute;
                width: 0;
                height: 0;
                border: 6px inset transparent;
            }
            .image-compare-left-arrow {
                left: 50%;
                margin-left: -17px;
                border-right: 6px solid #fff;
            }
            .image-compare-right-arrow {
                right: 50%;
                margin-right: -17px;
                border-left: 6px solid #fff;
            }
            .image-compare-line:before,
            .image-compare-line:after {
                position: absolute;
                z-index: 30;
                display: block;
                content: " ";
                background: #fff;
            }
            .image-compare-line:before {
                left: 50%;
                width: 3px;
                height: 9999px;
                bottom: 50%;
                margin-bottom: 22px;
                -webkit-box-shadow: 0 3px 0 #fff, 0px 0px 12px rgba(51, 51, 51, 0.5);
                box-shadow: 0 3px 0 #fff, 0px 0px 12px rgba(51, 51, 51, 0.5);
            }
            .image-compare-line:after {
                left: 50%;
                width: 3px;
                height: 9999px;
                top: 50%;
                margin-top: 22px;
                -webkit-box-shadow: 0 -3px 0 #fff, 0px 0px 12px rgba(51, 51, 51, 0.5);
                box-shadow: 0 -3px 0 #fff, 0px 0px 12px rgba(51, 51, 51, 0.5);
            }
            .text-block-css {
                display: inline-block;
                word-break: break-word;
                -webkit-background-clip: text !important;
            }
            .animationText span {
                display: inline-block;
            }
            .animationText .bar {
                position: absolute;
                height: 100%;
                width: 3px;
                background: currentColor;
                top: 0;
                right: 0;
            }
            .animate-word-wrapper {
                position: relative;
                overflow: hidden;
                vertical-align: top;
                transition: width 0.6s;
                text-align: start;
            }
            .animate-word-wrapper > span {
                white-space: nowrap;
                position: absolute;
                top: 0;
                left: 0;
                font: inherit;
            }
            .animate-word-wrapper span i {
                font: inherit;
                font-style: initial;
                display: inline-block;
                backface-visibility: hidden;
                -webkit-backface-visibility: hidden;
            }
            .animate-word-wrapper span i.in {
                animation-name: rotate-3-in;
                animation-fill-mode: forwards;
                animation-duration: 0.6s;
            }
            .animate-word-wrapper span i.out {
                animation-name: rotate-3-out;
                animation-fill-mode: forwards;
                animation-duration: 0.6s;
            }
            .animationText .hidden {
                opacity: 0;
                z-index: -1;
            }
            .animationText .visible {
                opacity: 1;
                position: relative;
            }
            .inline {
                display: inline !important;
            }
            .caret-dummy {
                animation: caret-popping 1s infinite;
            }
            .caret {
                transform: scaleY(1.3) translateY(-3%);
            }
            @keyframes rotate-3-out {
                0% {
                    transform: rotateY(0);
                    -webkit-transform: rotateY(0);
                }
                100% {
                    transform: rotateY(-180deg);
                    -webkit-transform: rotateY(-180deg);
                }
            }
            @-webkit-keyframes rotate-3-out {
                0% {
                    transform: rotateY(0);
                    -webkit-transform: rotateY(0);
                }
                100% {
                    transform: rotateY(-180deg);
                    -webkit-transform: rotateY(-180deg);
                }
            }
            @keyframes rotate-3-in {
                0% {
                    transform: rotateY(180deg);
                    -webkit-transform: rotateY(180deg);
                }
                100% {
                    transform: rotateY(0);
                    -webkit-transform: rotateY(0);
                }
            }
            @-webkit-keyframes rotate-3-in {
                0% {
                    transform: rotateY(180deg);
                    -webkit-transform: rotateY(180deg);
                }
                100% {
                    transform: rotateY(0);
                    -webkit-transform: rotateY(0);
                }
            }
            @keyframes caret-popping {
                0% {
                    opacity: 0;
                }
                50% {
                    opacity: 1;
                }
                100% {
                    opacity: 0;
                }
            }
            .group-container {
                width: 100%;
                height: 100%;
            }
            .com-button {
                cursor: pointer;
                user-select: none;
                -webkit-user-select: none;
                transition: transform 200ms ease-out;
                -webkit-transition: transform 200ms ease-out;
            }
            .com-button:active {
                transform: translateY(5px);
                -webkit-transform: translateY(5px);
            }
            .button-css {
                display: flex;
                display: -webkit-flex;
                align-items: center;
                -webkit-align-items: center;
                justify-content: center;
                -webkit-justify-content: center;
                text-align: center;
            }
            .button-text {
                display: table-cell;
                vertical-align: middle;
                text-align: unset;
            }
            .input-number-quantity-css {
                width: 100%;
                height: 100%;
                overflow: hidden;
                display: flex;
                user-select: none;
            }
            .input-number-quantity-css .minus,
            .plus {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;
                cursor: pointer;
            }
            .input-number-quantity-css .minus {
                border-right: inherit;
            }
            .input-number-quantity-css .plus {
                border-left: inherit;
            }
            .input-number-quantity-css input {
                text-align: center;
                background: transparent;
                color: inherit;
                width: 100%;
                height: 100%;
                border: 0;
                outline: none;
                padding-left: 10px;
                padding-right: 10px;
                box-sizing: border-box;
                border-radius: 0;
            }
            .input-number-quantity-css input::placeholder {
                color: #979797;
                -webkit-text-fill-color: #979797;
            }
            .input-number-quantity-css input::-webkit-outer-spin-button,
            .input-number-quantity-css input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            .input-number-quantity-css input[type="number"] {
                -moz-appearance: textfield;
            }
            .field-error {
                top: 0;
                right: 0;
                position: absolute;
                height: 100%;
                width: 30px;
            }
            .field-error img {
                width: 16px;
                height: 16px;
                position: absolute;
                left: calc(50% - 8px);
                top: calc(50% - 8px);
            }
            .input-css {
                overflow: hidden;
            }
            .input-css input {
                background: transparent;
                color: inherit;
                padding-left: 10px;
                border-radius: 0;
                text-align: inherit;
                letter-spacing: inherit;
            }
            .input-css input::placeholder {
                text-transform: var(--placeholder-transform);
                text-decoration: var(--placeholder-decoration);
            }
            .input-datetime {
                overflow: hidden;
            }
            .input-datetime input {
                background: transparent;
                width: 100%;
                height: 100%;
                padding-left: 10px;
                padding-right: 10px;
                border-radius: 0;
                box-sizing: border-box;
                -webkit-appearance: textfield;
                -moz-appearance: textfield;
                text-align: inherit;
                letter-spacing: inherit;
            }
            .input-datetime input::placeholder {
                text-transform: var(--placeholder-transform);
                text-decoration: var(--placeholder-decoration);
            }
            .input-datetime .input-datetime-placeholder {
                position: absolute;
                top: 0;
                padding-left: 12px;
                height: 100%;
                width: 100%;
                align-items: center;
                display: flex;
                z-index: -1;
            }
            .input-file {
                overflow: hidden;
                display: flex;
                display: -webkit-flex;
                align-items: center;
                -webkit-align-items: center;
                justify-content: space-between;
                -webkit-justify-content: space-between;
                padding: 0 10px;
                cursor: pointer;
            }
            .input-file .title {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .input-file input[type="file"] {
                opacity: 0;
                cursor: pointer;
                top: 0;
                left: 0;
            }
            .textarea-css {
                width: 100%;
                height: 100%;
                overflow: hidden;
            }
            .textarea-css textarea {
                background: transparent;
                padding-top: 6px;
                padding-bottom: 0;
                color: inherit;
                padding-left: 10px;
                border-radius: 0;
                text-align: inherit;
                letter-spacing: inherit;
            }
            .textarea-css textarea::placeholder {
                text-transform: var(--placeholder-transform);
                text-decoration: var(--placeholder-decoration);
            }
            .com-checkbox-group {
                min-height: 40px;
            }
            .checkbox-group-css {
                display: flex;
                display: -webkit-flex;
                flex-direction: row;
                -webkit-flex-direction: row;
                flex-wrap: wrap;
                -webkit-flex-wrap: wrap;
                padding-left: 10px;
                min-height: 40px;
            }
            .checkbox-group-item {
                display: flex;
                display: -webkit-flex;
                align-items: center;
                -webkit-align-items: center;
            }
            .checkbox-group-input {
                margin-right: 10px;
                width: 13px;
                height: 13px;
                min-width: 13px;
                min-height: 13px;
            }
            .com-radio {
                min-height: 40px;
                height: auto !important;
            }
            .radio-css {
                min-height: 40px;
                display: flex;
                display: -webkit-flex;
                flex-direction: row;
                -webkit-flex-direction: row;
                flex-wrap: wrap;
                -webkit-flex-wrap: wrap;
            }
            .radio-item {
                display: flex;
                display: -webkit-flex;
                align-items: center;
                -webkit-align-items: center;
                border-radius: 5px;
                padding-left: 10px;
            }
            .radio-input {
                margin-right: 10px;
                width: 13px;
                height: 13px;
                min-width: 13px;
                min-height: 13px;
            }
            .select-css {
                overflow: hidden;
                padding-top: 0;
                padding-bottom: 0;
            }
            .select-css select {
                text-align: inherit;
                letter-spacing: inherit;
            }
            .select-css option:first-child {
                color: #979797;
            }
            .chevron {
                pointer-events: none;
                color: #979797;
                position: absolute;
                right: 0;
                width: 26px;
                height: 100%;
                top: 0;
            }
            .chevron .icon {
                position: absolute;
                display: block;
                width: 14px;
                height: 14px;
                border: 2px solid transparent;
                border-radius: 100px;
                top: calc(50% - 7px);
                left: calc(50% - 7px);
            }
            .chevron .icon::after {
                content: "";
                display: block;
                position: absolute;
                width: 6px;
                height: 6px;
                border-bottom: 2px solid;
                border-right: 2px solid;
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
            }
            .select-css select {
                background: transparent;
                padding-right: 26px;
                padding-left: 10px;
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                -o-appearance: none;
                color: #979797;
            }
            .select-css select.select {
                color: inherit;
            }
            .address-css {
                overflow: hidden;
            }
            .address-css .select-container {
                color: inherit;
                padding-top: 0;
                padding-bottom: 0;
                border-radius: 0;
                display: flex;
                display: -webkit-flex;
                justify-content: space-between;
                -webkit-justify-content: space-between;
            }
            .address-css .select-field {
                height: 100%;
                flex: 1;
                position: relative;
            }
            .address-css select {
                background: transparent;
                padding-left: 10px;
                padding-right: 26px;
                width: 100%;
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                -o-appearance: none;
                height: 100%;
                color: #979797;
            }
            .address-css select.select {
                color: inherit;
            }
            .address-css select option:first-child {
                color: #979797;
            }
            .address-column {
                background: transparent !important;
                border-width: 0 !important;
            }
            .address-column .select-column {
                flex-direction: column;
                border-radius: inherit !important;
                border-color: inherit !important;
                border-style: inherit !important;
            }
            .address-column .select-column .select-field {
                width: 100%;
                border-radius: inherit;
                border-width: 1px;
                border-color: inherit;
                border-style: inherit;
                overflow: hidden;
            }
            .icon-container {
                position: absolute;
                left: calc(50% - 10px);
                top: calc(50% - 10px);
            }
            .loader {
                position: relative;
                height: 20px;
                width: 20px;
                display: inline-block;
                animation: around 5.4s infinite;
            }
            @keyframes around {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }
            .loader::after,
            .loader::before {
                content: "";
                background: white;
                position: absolute;
                display: inline-block;
                width: 100%;
                height: 100%;
                border-width: 2px;
                border-color: #333 #333 transparent transparent;
                border-style: solid;
                border-radius: 20px;
                box-sizing: border-box;
                top: 0;
                left: 0;
                animation: around 0.7s ease-in-out infinite;
            }
            .loader::after {
                animation: around 0.7s ease-in-out 0.1s infinite;
                background: transparent;
            }
            .button-loader,
            .button-loader:before,
            .button-loader:after {
                border-radius: 50%;
                width: 1.3em;
                height: 1.3em;
                animation-fill-mode: both;
                animation: bblFadInOut 1.8s infinite ease-in-out;
            }
            .button-loader {
                color: #fff;
                font-size: 7px;
                position: relative;
                text-indent: -9999em;
                transform: translateZ(0);
                animation-delay: -0.16s;
                display: none;
                top: -17px;
            }
            .button-loader:before,
            .button-loader:after {
                content: "";
                position: absolute;
                top: 0;
            }
            .button-loader:before {
                left: -3.5em;
                animation-delay: -0.32s;
            }
            .button-loader:after {
                left: 3.5em;
            }
            @keyframes bblFadInOut {
                0%,
                80%,
                100% {
                    box-shadow: 0 2.5em 0 -1.3em;
                }
                40% {
                    box-shadow: 0 2.5em 0 0;
                }
            }
            .popup-wrapper {
                top: 0;
                left: 0;
            }
            .popup-background {
                overflow: hidden;
            }
            .popup-backdrop {
                background: rgba(0, 0, 0, 0.5);
                position: fixed;
                top: 0;
                left: 0;
                z-index: 1000000;
            }
            .popup-center {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
            }
            .popup-top-left {
                top: 0;
                left: 0;
            }
            .popup-top-center {
                top: 0;
                left: 50%;
                transform: translate3d(-50%, 0, 0);
                -webkit-transform: translate3d(-50%, 0, 0);
            }
            .popup-top-right {
                top: 0;
                right: 0;
            }
            .popup-center-left {
                left: 0;
                top: 50%;
                transform: translate3d(0, -50%, 0);
                -webkit-transform: translate3d(0, -50%, 0);
            }
            .popup-center-right {
                right: 0;
                top: 50%;
                transform: translate3d(0, -50%, 0);
                -webkit-transform: translate3d(0, -50%, 0);
            }
            .popup-bottom-left {
                bottom: 0;
                left: 0;
            }
            .popup-bottom-center {
                bottom: 0;
                left: 50%;
                transform: translate3d(-50%, 0, 0);
                -webkit-transform: translate3d(-50%, 0, 0);
            }
            .popup-bottom-right {
                bottom: 0;
                right: 0;
            }
            .overlay {
                pointer-events: none;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                border-radius: inherit;
            }
            .cart-quantity-css {
                overflow: hidden;
                display: flex;
                display: -webkit-flex;
            }
            .cart-quantity-css .minus {
                border-right: inherit;
            }
            .cart-quantity-css .plus {
                border-left: inherit;
            }
            .cart-quantity-css input {
                text-align: center;
                background: transparent;
                color: inherit;
                padding-left: 10px;
                padding-right: 10px;
                border-radius: 0;
                -moz-appearance: textfield;
            }
            .cart-quantity-css input::-webkit-outer-spin-button,
            .cart-quantity-css input::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }
            .cart-quantity-css .minus,
            .cart-quantity-css .plus {
                display: flex;
                display: -webkit-flex;
                align-items: center;
                -webkit-align-items: center;
                justify-content: center;
                -webkit-justify-content: center;
                flex-shrink: 0;
                -webkit-flex-shrink: 0;
                user-select: none;
                cursor: pointer;
                -webkit-user-select: none;
                width: 40px;
            }
            .product-attr-selected {
                top: 0;
                left: 0;
                overflow: hidden;
            }
            .lightbox-btn-close {
                background-color: #a8a8a8;
                width: 21px;
                height: 21px;
                position: absolute;
                cursor: pointer;
            }
            .lightbox-btn-close > span {
                position: absolute;
                z-index: 9999;
                width: 14px;
                height: 14px;
                top: calc(50% - 7px);
                left: calc(50% - 7px);
                -webkit-mask-size: 100% 100%;
                -webkit-mask-position: 0% 0%;
                background: #fff;
                -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSdub25lJyAgd2lkdGg9JzE0JyBoZWlnaHQ9JzE0JyB2aWV3Qm94PScwIDAgMTQgMTQnIGZpbGw9J25vbmUnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTEyLjU5IDBMNyA1LjU5TDEuNDEgMEwwIDEuNDFMNS41OSA3TDAgMTIuNTlMMS40MSAxNEw3IDguNDFMMTIuNTkgMTRMMTQgMTIuNTlMOC40MSA3TDE0IDEuNDFMMTIuNTkgMFonIGZpbGw9JyMyRTNBNTknLz48L3N2Zz4=);
            }
            .lightbox-image,
            .lightbox-video,
            .lightbox-iframe {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                max-width: 80%;
                max-height: 80%;
            }
            .lightbox-iframe {
                width: 100%;
                height: 100%;
            }
            .lightbox-backdrop {
                width: 100%;
                background: rgba(0, 0, 0, 0.5);
                height: 100%;
                position: fixed;
                top: 0;
                left: 0;
                z-index: 1000000000;
                touch-action: none;
            }
            .w-alert-message-wrapper {
                position: fixed;
                top: 15px;
                z-index: 999999;
                left: 50%;
                padding: 10px 16px;
                border: 1px solid var(--color-alert, #15942a);
                -webkit-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                transform: translateX(-50%);
                --transform-translateX: -50%;
                background: white;
                border-radius: 8px;
                -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
                box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: stretch;
                -ms-flex-align: stretch;
                align-items: stretch;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
            }
            .w-alert-message {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
            }
            .w-alert-message .icon {
                line-height: 22px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                margin-left: 0px;
                margin-right: 6px;
            }
            .w-alert-message span.close-icon {
                margin-left: 6px;
                margin-right: 0px;
                color: #80808085;
                cursor: pointer;
            }
            .login-popup-wrapper .backdrop-login {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.5);
                display: none;
                z-index: 99;
            }
            .login-popup-wrapper .popup-default-login {
                position: fixed;
                top: 45%;
                left: 50%;
                width: 400px;
                height: 160px;
                transform: translate(-50%, -50%);
                z-index: 999;
                display: none;
                max-width: calc(100% - 50px);
                border: 1px solid rgba(0, 0, 0, 0.3);
                background-color: #fff;
                border-radius: 10px;
            }
            .login-popup-wrapper .popup-default-login .popup-login-title {
                background-color: rgba(6, 21, 40, 0.05);
                color: #000;
                padding: 12px 15px;
                font-weight: 600;
                font-size: 16px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
            .login-popup-wrapper .popup-default-login .popup-login-content {
                font-size: 14px;
                padding: 0 20px;
                margin-top: 10px;
                line-height: 18px;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
            }
            .login-popup-wrapper .popup-default-login .popup-login-btn {
                display: block;
                position: absolute;
                right: 15px;
                bottom: 10px;
                margin: 0 auto;
                padding: 10px 0;
                border: none;
                width: 80px;
                text-transform: uppercase;
                text-align: center;
                color: #000;
                background-color: #e6e6e6;
                border-radius: 5px;
                text-decoration: none;
                font-size: 14px;
                font-weight: 600;
                cursor: pointer;
            }
        </style>
        <style type="text/css" media="screen and (min-width: 768px)">
            .section-container {
                max-width: 420px;
            }
            .section-wrapper {
                margin: 0 auto;
                max-width: 420px;
            }
            .pageview {
                max-width: 420px;
                margin: 0 auto;
            }
            #w-voco957s {
                top: 0px;
                left: 0px;
                position: relative;
                width: 420px;
                height: 79px;
            }
            #w-voco957s .section-wrapper {
                opacity: 1;
            }
            #w-voco957s {
                margin-bottom: -1px;
            }
            #w-voco957s .section-background {
                background: center center/ cover no-repeat content-box border-box url("https://content.pancake.vn/1/s840x158/fwebp/7a/ab/18/a1/e8fc4a0c64990232ad89051c8dba27da64da1308b13ecad67e8939f1.PNG");
            }
            #w-ta7vru8h {
                top: 3px;
                left: 92px;
                width: 236px;
                height: 68px;
            }
            #w-ta7vru8h .image-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                border-width: 0px;
                position: absolute;
                z-index: ;
            }
            #w-ta7vru8h .image-background {
                width: 236px;
                height: 68px;
                top: 0px;
                left: 0px;
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s636x468/fwebp/f5/5d/63/f1/0ec6e33e95d079ade3b45cdf1fb7418750f318451b3382b6aa32c6bc.png");
            }
            #w-ta7vru8h .image-background.fb {
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s636x468/f5/5d/63/f1/0ec6e33e95d079ade3b45cdf1fb7418750f318451b3382b6aa32c6bc.png");
            }
            #w-ta7vru8h .image-background-compare {
                background: undefined;
                clip: rect(0, 118px, 68px, 0);
            }
            #w-8zrqau2v {
                top: 0px;
                left: 0px;
                position: relative;
                width: 420px;
                height: 909px;
            }
            #w-8zrqau2v .section-wrapper {
                opacity: 1;
            }
            #w-8zrqau2v {
                margin-bottom: -1px;
            }
            #w-8zrqau2v .section-background {
                background: center center/ cover no-repeat content-box border-box url("https://content.pancake.vn/1/s840x1818/fwebp/d0/6e/91/8c/3e7012d03c3362ae27d19c1800c6d27d6b3187ac68ae236a2e4f8e8e.jpg");
            }
            #w-589iypo5 {
                top: 11.5px;
                left: 11px;
                width: 360px;
                height: 120px;
            }
            #w-589iypo5 .text-block {
                background: ;
            }
            #w-589iypo5 .text-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                color: rgba(255, 254, 254, 1);
                font-family: "Poppins", sans-serif;
                font-size: 40px;
                font-weight: bold;
                text-align: left;
            }
            #w-e9gjaqpi {
                top: 131.5px;
                left: 11px;
                width: 403px;
                height: 264px;
            }
            #w-e9gjaqpi .text-block {
                background: ;
            }
            #w-e9gjaqpi .text-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                color: rgba(106, 109, 121, 1);
                font-size: 16px;
                text-align: left;
            }
            #w-37pkuy19 {
                top: 462.5px;
                left: 58px;
                width: 304px;
                height: 400px;
            }
            #w-37pkuy19 .image-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                border-width: 0px;
                position: absolute;
            }
            #w-37pkuy19 .image-background {
                width: 304px;
                height: 400px;
                top: 0px;
                left: 0px;
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s608x800/fwebp/ff/b4/8d/b6/05cef7ca82c529f24195eedab5e4b1e37ec846e05b01cfc0e4ed8164.png");
            }
            #w-37pkuy19 .image-background.fb {
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s608x800/ff/b4/8d/b6/05cef7ca82c529f24195eedab5e4b1e37ec846e05b01cfc0e4ed8164.png");
            }
            #w-37pkuy19 .image-background-compare {
                background: undefined;
                clip: rect(0, 152px, 400px, 0);
            }
            #w-7v659wcf {
                top: 408px;
                left: 28.5px;
                width: 343px;
                height: 80px;
            }
            #w-tzfmnreh {
                top: 11px;
                left: 8px;
                width: 327px;
                height: 60px;
            }
            #w-tzfmnreh .button-css {
                background: linear-gradient(90deg, rgba(237, 30, 121, 1) 0%, rgba(46, 49, 146, 1) 100%);
                border-color: rgba(229, 231, 235, 1);
                border-radius: 100px;
                border-style: solid;
                border-width: 2px;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                color: rgba(255, 255, 255, 1);
                font-size: 16px;
            }
            #w-k3l3pnlm {
                top: 22px;
                left: 116px;
                width: 36px;
                height: 36px;
            }
            #w-k3l3pnlm .image-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                border-width: 0px;
                position: absolute;
            }
            #w-k3l3pnlm .image-background {
                width: 36px;
                height: 36px;
                top: 0px;
                left: 0px;
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s436x436/fwebp/a5/42/6e/ce/515d1283e37de4ef157d261e56af54c4814e25cc7a9d26240542cbc9.png");
            }
            #w-k3l3pnlm .image-background.fb {
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s436x436/a5/42/6e/ce/515d1283e37de4ef157d261e56af54c4814e25cc7a9d26240542cbc9.png");
            }
            #w-k3l3pnlm .image-background-compare {
                background: undefined;
                clip: rect(0, 18px, 36px, 0);
            }
            #w-9rydxrbr {
                top: 0px;
                left: 0px;
                position: relative;
                width: 420px;
                height: 1425px;
            }
            #w-9rydxrbr .section-wrapper {
                opacity: 1;
            }
            #w-9rydxrbr {
                margin-bottom: -1px;
            }
            #w-9rydxrbr .section-background {
                background: center center/ cover no-repeat content-box border-box url("https://content.pancake.vn/1/s840x2850/fwebp/be/b2/cf/84/6d0a9303ece4ab073809c12d9cc5ff0054cb65f12eab5e774b2f0260.PNG");
            }
            #w-vtkx80qs {
                top: 16.5px;
                left: 11px;
                width: 231px;
                height: 95px;
            }
            #w-ebkz8kgf {
                top: 63px;
                left: 21px;
                width: 210px;
                height: 31.5px;
            }
            #w-ebkz8kgf .text-block {
                background: ;
            }
            #w-ebkz8kgf .text-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                color: rgba(255, 254, 254, 1);
                font-size: 21px;
                text-align: left;
            }
            #w-pmhheysf {
                top: 0px;
                left: 0px;
                width: 78px;
                height: 63px;
            }
            #w-pmhheysf .image-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                border-width: 0px;
                position: absolute;
            }
            #w-pmhheysf .image-background {
                width: 78px;
                height: 63px;
                top: 0px;
                left: 0px;
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s478x463/fwebp/d4/12/15/48/091d4b17fe101188f25cbf3f84c0dbfe799c9ebdc5025776b44952f4.PNG");
            }
            #w-pmhheysf .image-background.fb {
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s478x463/d4/12/15/48/091d4b17fe101188f25cbf3f84c0dbfe799c9ebdc5025776b44952f4.PNG");
            }
            #w-pmhheysf .image-background-compare {
                background: undefined;
                clip: rect(0, 39px, 63px, 0);
            }
            #w-4wpyv6jq {
                top: 126.5px;
                left: 11px;
                width: 274px;
                height: 95px;
            }
            #w-f8k7gtp9 {
                top: 63px;
                left: 21px;
                width: 253px;
                height: 31.5px;
            }
            #w-f8k7gtp9 .text-block {
                background: ;
            }
            #w-f8k7gtp9 .text-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                color: rgba(255, 254, 254, 1);
                font-size: 21px;
                text-align: left;
            }
            #w-4iftdm8r {
                top: 0px;
                left: 0px;
                width: 78px;
                height: 63px;
            }
            #w-4iftdm8r .image-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                border-width: 0px;
                position: absolute;
            }
            #w-4iftdm8r .image-background {
                width: 78px;
                height: 63px;
                top: 0px;
                left: 0px;
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s478x463/fwebp/48/b9/9f/b0/fe1b1a67196387674fcff5a77d1198c21018d584d0700583d0df2366.PNG");
            }
            #w-4iftdm8r .image-background.fb {
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s478x463/48/b9/9f/b0/fe1b1a67196387674fcff5a77d1198c21018d584d0700583d0df2366.PNG");
            }
            #w-4iftdm8r .image-background-compare {
                background: undefined;
                clip: rect(0, 39px, 63px, 0);
            }
            #w-ngdgddon {
                top: 252.5px;
                left: 11px;
                width: 274px;
                height: 95px;
            }
            #w-eg4exzbv {
                top: 63px;
                left: 21px;
                width: 253px;
                height: 31.5px;
            }
            #w-eg4exzbv .text-block {
                background: ;
            }
            #w-eg4exzbv .text-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                color: rgba(255, 254, 254, 1);
                font-size: 21px;
                text-align: left;
            }
            #w-l156e05q {
                top: 0px;
                left: 0px;
                width: 78px;
                height: 63px;
            }
            #w-l156e05q .image-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                border-width: 0px;
                position: absolute;
            }
            #w-l156e05q .image-background {
                width: 78px;
                height: 63px;
                top: 0px;
                left: 0px;
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s478x463/fwebp/8a/93/9c/45/284e36978d683721a489a641c19546cb9aae5cb67e7d775201f6fe1b.PNG");
            }
            #w-l156e05q .image-background.fb {
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s478x463/8a/93/9c/45/284e36978d683721a489a641c19546cb9aae5cb67e7d775201f6fe1b.PNG");
            }
            #w-l156e05q .image-background-compare {
                background: undefined;
                clip: rect(0, 39px, 63px, 0);
            }
            #w-npu7o5x1 {
                top: 465.5px;
                left: 50px;
                width: 314px;
                height: 314px;
            }
            #w-npu7o5x1 .image-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                border-width: 0px;
                position: absolute;
            }
            #w-npu7o5x1 .image-background {
                width: 314px;
                height: 314px;
                top: 0px;
                left: 0px;
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s714x714/fwebp/c8/c6/25/77/3c67fc7ef358bfb9ec1e331e41754893f9903d710a9ddeb8eed09624.webp");
            }
            #w-npu7o5x1 .image-background.fb {
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s714x714/fpng/c8/c6/25/77/3c67fc7ef358bfb9ec1e331e41754893f9903d710a9ddeb8eed09624.webp");
            }
            #w-npu7o5x1 .image-background-compare {
                background: undefined;
                clip: rect(0, 157px, 314px, 0);
            }
            #w-95m7iyb1 {
                top: 787.5px;
                left: 17px;
                width: 210px;
                height: 19.5px;
            }
            #w-95m7iyb1 .text-block {
                background: ;
            }
            #w-95m7iyb1 .text-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                color: rgba(106, 109, 121, 1);
                font-size: 13px;
                font-weight: bold;
                text-align: left;
            }
            #w-cd721j3o {
                top: 822.5px;
                left: 17px;
                width: 258px;
                height: 30px;
            }
            #w-cd721j3o .text-block {
                background: ;
            }
            #w-cd721j3o .text-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                color: rgba(255, 254, 254, 1);
                font-size: 20px;
                text-align: left;
            }
            #w-0q2sbnw6 {
                top: 860.5px;
                left: 17px;
                width: 349px;
                height: 48px;
            }
            #w-0q2sbnw6 .text-block {
                background: ;
            }
            #w-0q2sbnw6 .text-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                color: rgba(106, 109, 121, 1);
                font-size: 16px;
                text-align: left;
            }
            #w-ktn37du5 {
                top: 923.5px;
                left: 17px;
                width: 74px;
                height: 24px;
            }
            #w-l8seh9uc {
                top: 0px;
                left: 0px;
                width: 21px;
                height: 21px;
            }
            #w-l8seh9uc {
                box-shadow: undefined;
                filter: undefined;
            }
            #w-l8seh9uc .rectangle-css {
                -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSdub25lJyB3aWR0aD0nMTYnIGhlaWdodD0nMTInIHZpZXdCb3g9JzAgMCAxNiAxMicgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNNS41MjQ5NSAxMS42NTY4TDAuNTc0OTUxIDYuNzA2NzhMMS45ODg5NSA1LjI5Mjc4TDUuNjQyOTUgOC45NDI3OEw1LjUyNDk1IDguODI3NzhMMTQuMDEgMC4zNDI3NzNMMTUuNDI0IDEuNzU2NzhMNi45MzkgMTAuMjQyOEw1LjUyNTk1IDExLjY1NThMNS41MjQ5NSAxMS42NTY4WicgZmlsbD0nIzJFM0E1OScvPjwvc3ZnPg==);
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                background: rgba(106, 109, 121, 1);
            }
            #w-tejq9gc4 {
                top: 0px;
                left: 27px;
                width: 47px;
                height: 24px;
            }
            #w-tejq9gc4 .text-block {
                background: ;
            }
            #w-tejq9gc4 .text-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                color: rgba(106, 109, 121, 1);
                font-size: 16px;
                text-align: left;
            }
            #w-rdjeqqwh {
                top: 962.5px;
                left: 17px;
                width: 89px;
                height: 24px;
            }
            #w-pgccfxxz {
                top: 0px;
                left: 0px;
                width: 21px;
                height: 21px;
            }
            #w-pgccfxxz {
                box-shadow: undefined;
                filter: undefined;
            }
            #w-pgccfxxz .rectangle-css {
                -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSdub25lJyB3aWR0aD0nMTYnIGhlaWdodD0nMTInIHZpZXdCb3g9JzAgMCAxNiAxMicgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNNS41MjQ5NSAxMS42NTY4TDAuNTc0OTUxIDYuNzA2NzhMMS45ODg5NSA1LjI5Mjc4TDUuNjQyOTUgOC45NDI3OEw1LjUyNDk1IDguODI3NzhMMTQuMDEgMC4zNDI3NzNMMTUuNDI0IDEuNzU2NzhMNi45MzkgMTAuMjQyOEw1LjUyNTk1IDExLjY1NThMNS41MjQ5NSAxMS42NTY4WicgZmlsbD0nIzJFM0E1OScvPjwvc3ZnPg==);
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                background: rgba(106, 109, 121, 1);
            }
            #w-m6ha3e9g {
                top: 0px;
                left: 27px;
                width: 62px;
                height: 24px;
            }
            #w-m6ha3e9g .text-block {
                background: ;
            }
            #w-m6ha3e9g .text-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                color: rgba(106, 109, 121, 1);
                font-size: 16px;
                text-align: left;
            }
            #w-73dsf88l {
                top: 1080.5px;
                left: 94.5px;
                width: 231px;
                height: 75px;
            }
            #w-73dsf88l .text-block {
                background: ;
            }
            #w-73dsf88l .text-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                color: rgba(255, 254, 254, 1);
                font-size: 25px;
                text-align: center;
            }
            #w-0weh3068 {
                top: 1163px;
                left: 72px;
                width: 267px;
                height: 62px;
            }
            #w-zh9glscv {
                top: 0px;
                left: 0px;
                width: 134px;
                height: 62px;
            }
            #w-zh9glscv .image-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                border-width: 0px;
                position: absolute;
            }
            #w-zh9glscv .image-background {
                width: 134px;
                height: 62px;
                top: 0px;
                left: 0px;
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s534x462/fwebp/8e/9b/5d/df/76daa82054ea7e7d174a5e57019e4c4496a8de341267307eb3f75d03.PNG");
            }
            #w-zh9glscv .image-background.fb {
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s534x462/8e/9b/5d/df/76daa82054ea7e7d174a5e57019e4c4496a8de341267307eb3f75d03.PNG");
            }
            #w-zh9glscv .image-background-compare {
                background: undefined;
                clip: rect(0, 67px, 62px, 0);
            }
            #w-b3bszbmh {
                top: 0px;
                left: 134px;
                width: 133px;
                height: 62px;
            }
            #w-b3bszbmh .image-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                border-width: 0px;
                position: absolute;
            }
            #w-b3bszbmh .image-background {
                width: 133px;
                height: 62px;
                top: 0px;
                left: 0px;
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s533x462/fwebp/1e/50/5f/ff/e76ee915617b072e195f397738d85b44dc19977dd94dcd40ec784153.PNG");
            }
            #w-b3bszbmh .image-background.fb {
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s533x462/1e/50/5f/ff/e76ee915617b072e195f397738d85b44dc19977dd94dcd40ec784153.PNG");
            }
            #w-b3bszbmh .image-background-compare {
                background: undefined;
                clip: rect(0, 66.5px, 62px, 0);
            }
            #w-dskr2wku {
                top: 1240px;
                left: 17px;
                width: 104px;
                height: 30px;
            }
            #w-dskr2wku .image-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                border-width: 0px;
                position: absolute;
                z-index: ;
            }
            #w-dskr2wku .image-background {
                width: 104px;
                height: 30px;
                top: 0px;
                left: 0px;
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s504x430/fwebp/f5/5d/63/f1/0ec6e33e95d079ade3b45cdf1fb7418750f318451b3382b6aa32c6bc.png");
            }
            #w-dskr2wku .image-background.fb {
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s504x430/f5/5d/63/f1/0ec6e33e95d079ade3b45cdf1fb7418750f318451b3382b6aa32c6bc.png");
            }
            #w-dskr2wku .image-background-compare {
                background: undefined;
                clip: rect(0, 52px, 30px, 0);
            }
            #w-8y9rswzv {
                top: 1379.5px;
                left: 17px;
                width: 386px;
                height: 21px;
            }
            #w-8y9rswzv .text-block {
                background: ;
            }
            #w-8y9rswzv .text-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                color: rgba(106, 109, 121, 1);
                font-size: 14px;
                text-align: left;
            }
            #w-c10onnq9 {
                top: 1277.5px;
                left: 17px;
                width: 340px;
                height: 90px;
            }
            #w-c10onnq9 form {
                border-radius: 6px;
                font-size: 13px;
            }
            #w-c10onnq9 {
            }
            #w-s0323126 {
                top: 50px;
                left: 0px;
                width: 164px;
                height: 40px;
            }
            #w-s0323126 .button-css {
                background: rgba(233, 30, 99, 0.95);
                border-color: #e5e7eb;
                border-radius: 6px;
                border-style: solid;
                color: rgba(255, 255, 255, 1);
                font-size: 18px;
            }
            #w-g7s7quor {
                top: 0px;
                left: 0px;
                width: 340px;
                height: 40px;
            }
            #w-g7s7quor .input-css {
                border-color: rgba(217, 217, 217, 1);
                border-radius: 6px;
                border-style: solid;
                border-width: 1px;
                color: rgba(0, 0, 0, 1);
            }
            #w-g7s7quor input {
                background: rgba(255, 255, 255, 1);
                color: rgba(0, 0, 0, 1);
            }
            #w-g7s7quor input::placeholder {
                color: #979797;
                -webkit-text-fill-color: #979797 !important;
            }
            #w-9sff3x9y {
                top: 617px;
                left: 38px;
                width: 152px;
                height: 152px;
            }
            #w-9sff3x9y .image-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                border-width: 0px;
                position: absolute;
            }
            #w-9sff3x9y .image-background {
                width: 152px;
                height: 152px;
                top: 0px;
                left: 0px;
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s552x552/fwebp/99/03/c2/af/6ae158db418cb6db7983b29a6763b2d2b12012745a5484deef09f413.png");
            }
            #w-9sff3x9y .image-background.fb {
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s552x552/99/03/c2/af/6ae158db418cb6db7983b29a6763b2d2b12012745a5484deef09f413.png");
            }
            #w-9sff3x9y .image-background-compare {
                background: undefined;
                clip: rect(0, 76px, 152px, 0);
            }
            #w-v0woob4r {
                top: 1004.5px;
                left: 44px;
                width: 327px;
                height: 51px;
            }
            #w-0cw78uv8 {
                top: 0px;
                left: 0px;
                width: 327px;
                height: 51px;
            }
            #w-0cw78uv8 .button-css {
                background: linear-gradient(90deg, rgba(233, 30, 99, 0.95) 0%, rgba(233, 30, 99, 0.95) 100%);
                border-color: rgba(229, 231, 235, 1);
                border-radius: 5px;
                border-style: solid;
                border-width: 2px;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                color: rgba(255, 255, 255, 1);
                font-size: 16px;
            }
            #w-48gk4ij8 {
                top: 10.5px;
                left: 113.5px;
                width: 30px;
                height: 30px;
            }
            #w-48gk4ij8 .image-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                border-width: 0px;
                position: absolute;
            }
            #w-48gk4ij8 .image-background {
                width: 30px;
                height: 30px;
                top: 0px;
                left: 0px;
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s430x430/fwebp/a5/42/6e/ce/515d1283e37de4ef157d261e56af54c4814e25cc7a9d26240542cbc9.png");
            }
            #w-48gk4ij8 .image-background.fb {
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s430x430/a5/42/6e/ce/515d1283e37de4ef157d261e56af54c4814e25cc7a9d26240542cbc9.png");
            }
            #w-48gk4ij8 .image-background-compare {
                background: undefined;
                clip: rect(0, 15px, 30px, 0);
            }
            #w-__popup_default__ {
                position: fixed;
                width: 400px;
                height: 250px;
            }
            #w-__popup_default__::-webkit-scrollbar {
                display: none;
            }
            #w-__popup_default__ .popup-background {
                background: rgba(255, 255, 255, 1);
                border-color: rgba(76, 175, 80, 0.8);
                border-radius: 13px;
                border-style: solid;
                box-shadow: 4px 4px 9px 0px rgba(139, 195, 74, 0.2);
                color: rgba(69, 64, 64, 1);
            }
            #w-oc2dcqis {
                top: 21px;
                left: 36px;
                width: 176px;
                height: 54px;
            }
            #w-oc2dcqis .text-block {
                background: ;
            }
            #w-oc2dcqis .text-block-css {
                color: rgba(69, 64, 64, 1);
                font-family: "Roboto", sans-serif;
                font-size: 36px;
                font-weight: bold;
                text-align: start;
            }
            #w-pn1igdhr {
                top: 89px;
                left: 36px;
                width: 328px;
                height: 48px;
            }
            #w-pn1igdhr .text-block {
                background: ;
            }
            #w-pn1igdhr .text-block-css {
                font-family: "Roboto", sans-serif;
                font-size: 16px;
                text-align: start;
            }
            #w-au0o7yag {
                top: 184px;
                left: 241.5px;
                width: 130px;
                height: 40px;
            }
            #w-au0o7yag .button-css {
                background: rgba(76, 175, 80, 0.8);
                border-radius: 6px;
                border-style: solid;
                color: rgba(255, 255, 255, 1);
                font-weight: bold;
            }
            #w-au0o7yap {
                top: 0px;
                left: 370px;
                z-index: 1;
                width: 29px;
                height: 29px;
            }
            #w-au0o7yap {
                box-shadow: undefined;
                filter: undefined;
            }
            #w-au0o7yap .rectangle-css {
                -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSdub25lJyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMDAlJyBoZWlnaHQ9JzEwMCUnIHZpZXdCb3g9JzAgMCAyNCAyNCc+IDxwYXRoIGQ9J00xMy40NiwxMkwxOSwxNy41NFYxOUgxNy41NEwxMiwxMy40Nkw2LjQ2LDE5SDVWMTcuNTRMMTAuNTQsMTJMNSw2LjQ2VjVINi40NkwxMiwxMC41NEwxNy41NCw1SDE5VjYuNDZMMTMuNDYsMTJaJz48L3BhdGg+IDwvc3ZnPg==);
                border-color: #e5e7eb;
                border-style: solid;
                z-index: 1;
                background: rgb(212, 212, 212);
            }
            .-popup-checkout .-close,
            .-zoom .-close {
                width: undefinedpx;
                height: undefinedpx;
            }
            .-checkout-gallery .gallery-view-item {
                background-size: cover !important;
            }
            #w-7v659wcf.animation {
                transform: perspective(1000px);
            }
            #w-7v659wcf.animation > .group-container {
                animation-name: pulse;
                -webkit-animation-name: pulse;
                animation-delay: 0s;
                -webkit-animation-delay: 0s;
                animation-iteration-count: infinite;
                -webkit-animation-iteration-count: infinite;
                animation-duration: 1s;
                -webkit-animation-duration: 1s;
            }
            #w-v0woob4r.animation {
                transform: perspective(1000px);
            }
            #w-v0woob4r.animation > .group-container {
                animation-name: pulse;
                -webkit-animation-name: pulse;
                animation-delay: 0s;
                -webkit-animation-delay: 0s;
                animation-iteration-count: infinite;
                -webkit-animation-iteration-count: infinite;
                animation-duration: 1s;
                -webkit-animation-duration: 1s;
            }
        </style>
        <style type="text/css" media="screen and (max-width: 767px)">
            .section-container {
                max-width: 420px;
            }
            .section-wrapper {
                margin: 0 auto;
                max-width: 420px;
            }
            #w-voco957s {
                top: 0px;
                left: 0px;
                position: relative;
                width: 420px;
                height: 79px;
            }
            #w-voco957s .section-wrapper {
                opacity: 1;
            }
            #w-voco957s {
                margin-bottom: -1px;
            }
            #w-voco957s .section-background {
                background: center center/ cover no-repeat content-box border-box url("https://content.pancake.vn/1/s840x158/fwebp/7a/ab/18/a1/e8fc4a0c64990232ad89051c8dba27da64da1308b13ecad67e8939f1.PNG");
            }
            #w-ta7vru8h {
                top: 3px;
                left: 92px;
                width: 236px;
                height: 68px;
            }
            #w-ta7vru8h .image-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                border-width: 0px;
                position: absolute;
                z-index: ;
            }
            #w-ta7vru8h .image-background {
                width: 236px;
                height: 68px;
                top: 0px;
                left: 0px;
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s636x468/fwebp/f5/5d/63/f1/0ec6e33e95d079ade3b45cdf1fb7418750f318451b3382b6aa32c6bc.png");
            }
            #w-ta7vru8h .image-background.fb {
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s636x468/f5/5d/63/f1/0ec6e33e95d079ade3b45cdf1fb7418750f318451b3382b6aa32c6bc.png");
            }
            #w-ta7vru8h .image-background-compare {
                background: undefined;
                clip: rect(0, 118px, 68px, 0);
            }
            #w-8zrqau2v {
                top: 0px;
                left: 0px;
                position: relative;
                width: 420px;
                height: 909px;
            }
            #w-8zrqau2v .section-wrapper {
                opacity: 1;
            }
            #w-8zrqau2v {
                margin-bottom: -1px;
            }
            #w-8zrqau2v .section-background {
                background: center center/ cover no-repeat content-box border-box url("https://content.pancake.vn/1/s840x1818/fwebp/d0/6e/91/8c/3e7012d03c3362ae27d19c1800c6d27d6b3187ac68ae236a2e4f8e8e.jpg");
            }
            #w-589iypo5 {
                top: 11.5px;
                left: 11px;
                width: 360px;
                height: 120px;
            }
            #w-589iypo5 .text-block {
                background: ;
            }
            #w-589iypo5 .text-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                color: rgba(255, 254, 254, 1);
                font-family: "Poppins", sans-serif;
                font-size: 40px;
                font-weight: bold;
                text-align: left;
            }
            #w-e9gjaqpi {
                top: 131.5px;
                left: 11px;
                width: 403px;
                height: 264px;
            }
            #w-e9gjaqpi .text-block {
                background: ;
            }
            #w-e9gjaqpi .text-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                color: rgba(106, 109, 121, 1);
                font-size: 16px;
                text-align: left;
            }
            #w-37pkuy19 {
                top: 462.5px;
                left: 58px;
                width: 304px;
                height: 400px;
            }
            #w-37pkuy19 .image-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                border-width: 0px;
                position: absolute;
            }
            #w-37pkuy19 .image-background {
                width: 304px;
                height: 400px;
                top: 0px;
                left: 0px;
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s608x800/fwebp/ff/b4/8d/b6/05cef7ca82c529f24195eedab5e4b1e37ec846e05b01cfc0e4ed8164.png");
            }
            #w-37pkuy19 .image-background.fb {
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s608x800/ff/b4/8d/b6/05cef7ca82c529f24195eedab5e4b1e37ec846e05b01cfc0e4ed8164.png");
            }
            #w-37pkuy19 .image-background-compare {
                background: undefined;
                clip: rect(0, 152px, 400px, 0);
            }
            #w-7v659wcf {
                top: 408px;
                left: 28.5px;
                width: 327px;
                height: 40px;
            }
            #w-tzfmnreh {
                top: 11px;
                left: 8px;
                width: 327px;
                height: 60px;
            }
            #w-tzfmnreh .button-css {
                background: linear-gradient(90deg, rgba(237, 30, 121, 1) 0%, rgba(46, 49, 146, 1) 100%);
                border-color: rgba(229, 231, 235, 1);
                border-radius: 100px;
                border-style: solid;
                border-width: 2px;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                color: rgba(255, 255, 255, 1);
                font-size: 16px;
            }
            #w-k3l3pnlm {
                top: 22px;
                left: 116px;
                width: 36px;
                height: 36px;
            }
            #w-k3l3pnlm .image-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                border-width: 0px;
                position: absolute;
            }
            #w-k3l3pnlm .image-background {
                width: 36px;
                height: 36px;
                top: 0px;
                left: 0px;
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s436x436/fwebp/a5/42/6e/ce/515d1283e37de4ef157d261e56af54c4814e25cc7a9d26240542cbc9.png");
            }
            #w-k3l3pnlm .image-background.fb {
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s436x436/a5/42/6e/ce/515d1283e37de4ef157d261e56af54c4814e25cc7a9d26240542cbc9.png");
            }
            #w-k3l3pnlm .image-background-compare {
                background: undefined;
                clip: rect(0, 18px, 36px, 0);
            }
            #w-9rydxrbr {
                top: 0px;
                left: 0px;
                position: relative;
                width: 420px;
                height: 1425px;
            }
            #w-9rydxrbr .section-wrapper {
                opacity: 1;
            }
            #w-9rydxrbr {
                margin-bottom: -1px;
            }
            #w-9rydxrbr .section-background {
                background: center center/ cover no-repeat content-box border-box url("https://content.pancake.vn/1/s840x2850/fwebp/be/b2/cf/84/6d0a9303ece4ab073809c12d9cc5ff0054cb65f12eab5e774b2f0260.PNG");
            }
            #w-vtkx80qs {
                top: 16.5px;
                left: 11px;
                width: 231px;
                height: 95px;
            }
            #w-ebkz8kgf {
                top: 63px;
                left: 21px;
                width: 210px;
                height: 31.5px;
            }
            #w-ebkz8kgf .text-block {
                background: ;
            }
            #w-ebkz8kgf .text-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                color: rgba(255, 254, 254, 1);
                font-size: 21px;
                text-align: left;
            }
            #w-pmhheysf {
                top: 0px;
                left: 0px;
                width: 78px;
                height: 63px;
            }
            #w-pmhheysf .image-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                border-width: 0px;
                position: absolute;
            }
            #w-pmhheysf .image-background {
                width: 78px;
                height: 63px;
                top: 0px;
                left: 0px;
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s478x463/fwebp/d4/12/15/48/091d4b17fe101188f25cbf3f84c0dbfe799c9ebdc5025776b44952f4.PNG");
            }
            #w-pmhheysf .image-background.fb {
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s478x463/d4/12/15/48/091d4b17fe101188f25cbf3f84c0dbfe799c9ebdc5025776b44952f4.PNG");
            }
            #w-pmhheysf .image-background-compare {
                background: undefined;
                clip: rect(0, 39px, 63px, 0);
            }
            #w-4wpyv6jq {
                top: 126.5px;
                left: 11px;
                width: 274px;
                height: 95px;
            }
            #w-f8k7gtp9 {
                top: 63px;
                left: 21px;
                width: 253px;
                height: 31.5px;
            }
            #w-f8k7gtp9 .text-block {
                background: ;
            }
            #w-f8k7gtp9 .text-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                color: rgba(255, 254, 254, 1);
                font-size: 21px;
                text-align: left;
            }
            #w-4iftdm8r {
                top: 0px;
                left: 0px;
                width: 78px;
                height: 63px;
            }
            #w-4iftdm8r .image-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                border-width: 0px;
                position: absolute;
            }
            #w-4iftdm8r .image-background {
                width: 78px;
                height: 63px;
                top: 0px;
                left: 0px;
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s478x463/fwebp/48/b9/9f/b0/fe1b1a67196387674fcff5a77d1198c21018d584d0700583d0df2366.PNG");
            }
            #w-4iftdm8r .image-background.fb {
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s478x463/48/b9/9f/b0/fe1b1a67196387674fcff5a77d1198c21018d584d0700583d0df2366.PNG");
            }
            #w-4iftdm8r .image-background-compare {
                background: undefined;
                clip: rect(0, 39px, 63px, 0);
            }
            #w-ngdgddon {
                top: 252.5px;
                left: 11px;
                width: 274px;
                height: 95px;
            }
            #w-eg4exzbv {
                top: 63px;
                left: 21px;
                width: 253px;
                height: 31.5px;
            }
            #w-eg4exzbv .text-block {
                background: ;
            }
            #w-eg4exzbv .text-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                color: rgba(255, 254, 254, 1);
                font-size: 21px;
                text-align: left;
            }
            #w-l156e05q {
                top: 0px;
                left: 0px;
                width: 78px;
                height: 63px;
            }
            #w-l156e05q .image-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                border-width: 0px;
                position: absolute;
            }
            #w-l156e05q .image-background {
                width: 78px;
                height: 63px;
                top: 0px;
                left: 0px;
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s478x463/fwebp/8a/93/9c/45/284e36978d683721a489a641c19546cb9aae5cb67e7d775201f6fe1b.PNG");
            }
            #w-l156e05q .image-background.fb {
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s478x463/8a/93/9c/45/284e36978d683721a489a641c19546cb9aae5cb67e7d775201f6fe1b.PNG");
            }
            #w-l156e05q .image-background-compare {
                background: undefined;
                clip: rect(0, 39px, 63px, 0);
            }
            #w-npu7o5x1 {
                top: 465.5px;
                left: 50px;
                width: 314px;
                height: 314px;
            }
            #w-npu7o5x1 .image-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                border-width: 0px;
                position: absolute;
            }
            #w-npu7o5x1 .image-background {
                width: 314px;
                height: 314px;
                top: 0px;
                left: 0px;
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s714x714/fwebp/c8/c6/25/77/3c67fc7ef358bfb9ec1e331e41754893f9903d710a9ddeb8eed09624.webp");
            }
            #w-npu7o5x1 .image-background.fb {
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s714x714/fpng/c8/c6/25/77/3c67fc7ef358bfb9ec1e331e41754893f9903d710a9ddeb8eed09624.webp");
            }
            #w-npu7o5x1 .image-background-compare {
                background: undefined;
                clip: rect(0, 157px, 314px, 0);
            }
            #w-95m7iyb1 {
                top: 787.5px;
                left: 17px;
                width: 210px;
                height: 19.5px;
            }
            #w-95m7iyb1 .text-block {
                background: ;
            }
            #w-95m7iyb1 .text-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                color: rgba(106, 109, 121, 1);
                font-size: 13px;
                font-weight: bold;
                text-align: left;
            }
            #w-cd721j3o {
                top: 822.5px;
                left: 17px;
                width: 258px;
                height: 30px;
            }
            #w-cd721j3o .text-block {
                background: ;
            }
            #w-cd721j3o .text-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                color: rgba(255, 254, 254, 1);
                font-size: 20px;
                text-align: left;
            }
            #w-0q2sbnw6 {
                top: 860.5px;
                left: 17px;
                width: 349px;
                height: 48px;
            }
            #w-0q2sbnw6 .text-block {
                background: ;
            }
            #w-0q2sbnw6 .text-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                color: rgba(106, 109, 121, 1);
                font-size: 16px;
                text-align: left;
            }
            #w-ktn37du5 {
                top: 923.5px;
                left: 17px;
                width: 74px;
                height: 24px;
            }
            #w-l8seh9uc {
                top: 0px;
                left: 0px;
                width: 21px;
                height: 21px;
            }
            #w-l8seh9uc {
                box-shadow: undefined;
                filter: undefined;
            }
            #w-l8seh9uc .rectangle-css {
                -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSdub25lJyB3aWR0aD0nMTYnIGhlaWdodD0nMTInIHZpZXdCb3g9JzAgMCAxNiAxMicgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNNS41MjQ5NSAxMS42NTY4TDAuNTc0OTUxIDYuNzA2NzhMMS45ODg5NSA1LjI5Mjc4TDUuNjQyOTUgOC45NDI3OEw1LjUyNDk1IDguODI3NzhMMTQuMDEgMC4zNDI3NzNMMTUuNDI0IDEuNzU2NzhMNi45MzkgMTAuMjQyOEw1LjUyNTk1IDExLjY1NThMNS41MjQ5NSAxMS42NTY4WicgZmlsbD0nIzJFM0E1OScvPjwvc3ZnPg==);
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                background: rgba(106, 109, 121, 1);
            }
            #w-tejq9gc4 {
                top: 0px;
                left: 27px;
                width: 47px;
                height: 24px;
            }
            #w-tejq9gc4 .text-block {
                background: ;
            }
            #w-tejq9gc4 .text-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                color: rgba(106, 109, 121, 1);
                font-size: 16px;
                text-align: left;
            }
            #w-rdjeqqwh {
                top: 962.5px;
                left: 17px;
                width: 89px;
                height: 24px;
            }
            #w-pgccfxxz {
                top: 0px;
                left: 0px;
                width: 21px;
                height: 21px;
            }
            #w-pgccfxxz {
                box-shadow: undefined;
                filter: undefined;
            }
            #w-pgccfxxz .rectangle-css {
                -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSdub25lJyB3aWR0aD0nMTYnIGhlaWdodD0nMTInIHZpZXdCb3g9JzAgMCAxNiAxMicgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNNS41MjQ5NSAxMS42NTY4TDAuNTc0OTUxIDYuNzA2NzhMMS45ODg5NSA1LjI5Mjc4TDUuNjQyOTUgOC45NDI3OEw1LjUyNDk1IDguODI3NzhMMTQuMDEgMC4zNDI3NzNMMTUuNDI0IDEuNzU2NzhMNi45MzkgMTAuMjQyOEw1LjUyNTk1IDExLjY1NThMNS41MjQ5NSAxMS42NTY4WicgZmlsbD0nIzJFM0E1OScvPjwvc3ZnPg==);
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                background: rgba(106, 109, 121, 1);
            }
            #w-m6ha3e9g {
                top: 0px;
                left: 27px;
                width: 62px;
                height: 24px;
            }
            #w-m6ha3e9g .text-block {
                background: ;
            }
            #w-m6ha3e9g .text-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                color: rgba(106, 109, 121, 1);
                font-size: 16px;
                text-align: left;
            }
            #w-73dsf88l {
                top: 1080.5px;
                left: 94.5px;
                width: 231px;
                height: 75px;
            }
            #w-73dsf88l .text-block {
                background: ;
            }
            #w-73dsf88l .text-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                color: rgba(255, 254, 254, 1);
                font-size: 25px;
                text-align: center;
            }
            #w-0weh3068 {
                top: 1163px;
                left: 72px;
                width: 267px;
                height: 62px;
            }
            #w-zh9glscv {
                top: 0px;
                left: 0px;
                width: 134px;
                height: 62px;
            }
            #w-zh9glscv .image-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                border-width: 0px;
                position: absolute;
            }
            #w-zh9glscv .image-background {
                width: 134px;
                height: 62px;
                top: 0px;
                left: 0px;
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s534x462/fwebp/8e/9b/5d/df/76daa82054ea7e7d174a5e57019e4c4496a8de341267307eb3f75d03.PNG");
            }
            #w-zh9glscv .image-background.fb {
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s534x462/8e/9b/5d/df/76daa82054ea7e7d174a5e57019e4c4496a8de341267307eb3f75d03.PNG");
            }
            #w-zh9glscv .image-background-compare {
                background: undefined;
                clip: rect(0, 67px, 62px, 0);
            }
            #w-b3bszbmh {
                top: 0px;
                left: 134px;
                width: 133px;
                height: 62px;
            }
            #w-b3bszbmh .image-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                border-width: 0px;
                position: absolute;
            }
            #w-b3bszbmh .image-background {
                width: 133px;
                height: 62px;
                top: 0px;
                left: 0px;
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s533x462/fwebp/1e/50/5f/ff/e76ee915617b072e195f397738d85b44dc19977dd94dcd40ec784153.PNG");
            }
            #w-b3bszbmh .image-background.fb {
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s533x462/1e/50/5f/ff/e76ee915617b072e195f397738d85b44dc19977dd94dcd40ec784153.PNG");
            }
            #w-b3bszbmh .image-background-compare {
                background: undefined;
                clip: rect(0, 66.5px, 62px, 0);
            }
            #w-dskr2wku {
                top: 1240px;
                left: 17px;
                width: 104px;
                height: 30px;
            }
            #w-dskr2wku .image-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                border-width: 0px;
                position: absolute;
                z-index: ;
            }
            #w-dskr2wku .image-background {
                width: 104px;
                height: 30px;
                top: 0px;
                left: 0px;
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s504x430/fwebp/f5/5d/63/f1/0ec6e33e95d079ade3b45cdf1fb7418750f318451b3382b6aa32c6bc.png");
            }
            #w-dskr2wku .image-background.fb {
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s504x430/f5/5d/63/f1/0ec6e33e95d079ade3b45cdf1fb7418750f318451b3382b6aa32c6bc.png");
            }
            #w-dskr2wku .image-background-compare {
                background: undefined;
                clip: rect(0, 52px, 30px, 0);
            }
            #w-8y9rswzv {
                top: 1379.5px;
                left: 17px;
                width: 386px;
                height: 21px;
            }
            #w-8y9rswzv .text-block {
                background: ;
            }
            #w-8y9rswzv .text-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                color: rgba(106, 109, 121, 1);
                font-size: 14px;
                text-align: left;
            }
            #w-c10onnq9 {
                top: 1277.5px;
                left: 17px;
                width: 340px;
                height: 90px;
            }
            #w-c10onnq9 form {
                border-radius: 6px;
                font-size: 13px;
            }
            #w-c10onnq9 {
            }
            #w-s0323126 {
                top: 50px;
                left: 0px;
                width: 164px;
                height: 40px;
            }
            #w-s0323126 .button-css {
                background: rgba(233, 30, 99, 0.95);
                border-color: #e5e7eb;
                border-radius: 6px;
                border-style: solid;
                color: rgba(255, 255, 255, 1);
                font-size: 18px;
            }
            #w-g7s7quor {
                top: 0px;
                left: 0px;
                width: 340px;
                height: 40px;
            }
            #w-g7s7quor .input-css {
                border-color: rgba(217, 217, 217, 1);
                border-radius: 6px;
                border-style: solid;
                border-width: 1px;
                color: rgba(0, 0, 0, 1);
            }
            #w-g7s7quor input {
                background: rgba(255, 255, 255, 1);
                color: rgba(0, 0, 0, 1);
            }
            #w-g7s7quor input::placeholder {
                color: #979797;
                -webkit-text-fill-color: #979797 !important;
            }
            #w-9sff3x9y {
                top: 617px;
                left: 38px;
                width: 152px;
                height: 152px;
            }
            #w-9sff3x9y .image-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                border-width: 0px;
                position: absolute;
            }
            #w-9sff3x9y .image-background {
                width: 152px;
                height: 152px;
                top: 0px;
                left: 0px;
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s552x552/fwebp/99/03/c2/af/6ae158db418cb6db7983b29a6763b2d2b12012745a5484deef09f413.png");
            }
            #w-9sff3x9y .image-background.fb {
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s552x552/99/03/c2/af/6ae158db418cb6db7983b29a6763b2d2b12012745a5484deef09f413.png");
            }
            #w-9sff3x9y .image-background-compare {
                background: undefined;
                clip: rect(0, 76px, 152px, 0);
            }
            #w-v0woob4r {
                top: 1004.5px;
                left: 44px;
                width: 327px;
                height: 51px;
            }
            #w-0cw78uv8 {
                top: 0px;
                left: 0px;
                width: 327px;
                height: 51px;
            }
            #w-0cw78uv8 .button-css {
                background: linear-gradient(90deg, rgba(233, 30, 99, 0.95) 0%, rgba(233, 30, 99, 0.95) 100%);
                border-color: rgba(229, 231, 235, 1);
                border-radius: 5px;
                border-style: solid;
                border-width: 2px;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                color: rgba(255, 255, 255, 1);
                font-size: 16px;
            }
            #w-48gk4ij8 {
                top: 10.5px;
                left: 113.5px;
                width: 30px;
                height: 30px;
            }
            #w-48gk4ij8 .image-block-css {
                border-color: rgba(229, 231, 235, 1);
                border-style: solid;
                border-width: 0px;
                position: absolute;
            }
            #w-48gk4ij8 .image-background {
                width: 30px;
                height: 30px;
                top: 0px;
                left: 0px;
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s430x430/fwebp/a5/42/6e/ce/515d1283e37de4ef157d261e56af54c4814e25cc7a9d26240542cbc9.png");
            }
            #w-48gk4ij8 .image-background.fb {
                background: center center/ cover no-repeat scroll content-box border-box url("https://content.pancake.vn/1/s430x430/a5/42/6e/ce/515d1283e37de4ef157d261e56af54c4814e25cc7a9d26240542cbc9.png");
            }
            #w-48gk4ij8 .image-background-compare {
                background: undefined;
                clip: rect(0, 15px, 30px, 0);
            }
            #w-__popup_default__ {
                position: fixed;
                width: 400px;
                height: 250px;
            }
            #w-__popup_default__::-webkit-scrollbar {
                display: none;
            }
            #w-__popup_default__ .popup-background {
                background: rgba(255, 255, 255, 1);
                border-color: rgba(76, 175, 80, 0.8);
                border-radius: 13px;
                border-style: solid;
                box-shadow: 4px 4px 9px 0px rgba(139, 195, 74, 0.2);
                color: rgba(69, 64, 64, 1);
            }
            #w-oc2dcqis {
                top: 21px;
                left: 36px;
                width: 176px;
                height: 54px;
            }
            #w-oc2dcqis .text-block {
                background: ;
            }
            #w-oc2dcqis .text-block-css {
                color: rgba(69, 64, 64, 1);
                font-family: "Roboto", sans-serif;
                font-size: 36px;
                font-weight: bold;
                text-align: start;
            }
            #w-pn1igdhr {
                top: 89px;
                left: 36px;
                width: 328px;
                height: 48px;
            }
            #w-pn1igdhr .text-block {
                background: ;
            }
            #w-pn1igdhr .text-block-css {
                font-family: "Roboto", sans-serif;
                font-size: 16px;
                text-align: start;
            }
            #w-au0o7yag {
                top: 184px;
                left: 241.5px;
                width: 130px;
                height: 40px;
            }
            #w-au0o7yag .button-css {
                background: rgba(76, 175, 80, 0.8);
                border-radius: 6px;
                border-style: solid;
                color: rgba(255, 255, 255, 1);
                font-weight: bold;
            }
            #w-au0o7yap {
                top: 0px;
                left: 370px;
                z-index: 1;
                width: 29px;
                height: 29px;
            }
            #w-au0o7yap {
                box-shadow: undefined;
                filter: undefined;
            }
            #w-au0o7yap .rectangle-css {
                -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSdub25lJyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMDAlJyBoZWlnaHQ9JzEwMCUnIHZpZXdCb3g9JzAgMCAyNCAyNCc+IDxwYXRoIGQ9J00xMy40NiwxMkwxOSwxNy41NFYxOUgxNy41NEwxMiwxMy40Nkw2LjQ2LDE5SDVWMTcuNTRMMTAuNTQsMTJMNSw2LjQ2VjVINi40NkwxMiwxMC41NEwxNy41NCw1SDE5VjYuNDZMMTMuNDYsMTJaJz48L3BhdGg+IDwvc3ZnPg==);
                border-color: #e5e7eb;
                border-style: solid;
                z-index: 1;
                background: rgb(212, 212, 212);
            }
            .-popup-checkout .-close,
            .-zoom .-close {
                width: undefinedpx;
                height: undefinedpx;
            }
            .-checkout-gallery .gallery-view-item {
                background-size: cover !important;
            }
            #w-7v659wcf.animation {
                transform: perspective(1000px);
            }
            #w-7v659wcf.animation > .group-container {
                animation-name: pulse;
                -webkit-animation-name: pulse;
                animation-delay: 0s;
                -webkit-animation-delay: 0s;
                animation-iteration-count: infinite;
                -webkit-animation-iteration-count: infinite;
                animation-duration: 1s;
                -webkit-animation-duration: 1s;
            }
            #w-v0woob4r.animation {
                transform: perspective(1000px);
            }
            #w-v0woob4r.animation > .group-container {
                animation-name: pulse;
                -webkit-animation-name: pulse;
                animation-delay: 0s;
                -webkit-animation-delay: 0s;
                animation-iteration-count: infinite;
                -webkit-animation-iteration-count: infinite;
                animation-duration: 1s;
                -webkit-animation-duration: 1s;
            }
        </style>

        <script async="" src="/address_wc/address.84.min.js?v=1"></script>

        <script>
            !(function (e, t) {
                var a = t.createElement("script");
                (a.async = !0), (a.src = "https://a.pancake.vn/js/app.js?vsn=d");
                var n = t.getElementsByTagName("script")[0];
                n.parentNode.insertBefore(a, n),
                    (e.ab = function () {
                        (ab.q = ab.q || []).push(arguments);
                    });
            })(window, document);
            ab("init", "ab-lp-86162010-7cd2-44c6-aa0e-81d7c17be7aa");
        </script>
    </head>
    <body ontouchstart="" cz-shortcut-listen="true">
        <div class="pageview" style="font-family: 'Poppins', san-serif;">
            <div id="webcake-alert-msg" class="w-alert-message-wrapper" style="display: none; z-index: 9999999;"></div>
            <div id="w-voco957s">
                <div class="section-wrapper full-width full-height p-relative">
                    <div class="section-background p-absolute full-width full-height"></div>
                    <div class="section-container full-height p-relative">
                        <div id="w-ta7vru8h" class="p-absolute">
                            <div class="image-block-css p-relative full-width full-height full-mask-size mask-position"><div class="image-background p-absolute"></div></div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="w-8zrqau2v">
                <div class="section-wrapper full-width full-height p-relative">
                    <div class="section-background p-absolute full-width full-height"></div>
                    <div class="section-container full-height p-relative">
                        <div id="w-589iypo5" class="com-text-block p-absolute">
                            <div class="text-block">
                                <h4 class="text-block-css full-width">Pi Network <span style="color: rgba(233, 30, 99, 0.95);">First</span> Airdrop<br /></h4>
                            </div>
                        </div>
                        <div id="w-e9gjaqpi" class="com-text-block p-absolute">
                            <div class="text-block">
                                <h5 class="text-block-css full-width">
                                    The official Pi Network has reached 2 million pioneers! To get closer to the mainnet and activate and attract more pioneers, they will be holding an airdrop, awarding a total of 1014π/pioneer prizes to
                                    those who successfully complete KYC. The future looks very exciting as the Pi community continues to build the Web3 Pi ecosystem full of amazing apps and utilities on top of the Pi browser. Let’s get the
                                    job done right and do everything we can to support this exciting development.
                                </h5>
                            </div>
                        </div>
                        <div id="w-37pkuy19" class="p-absolute">
                            <div class="image-block-css p-relative full-width full-height full-mask-size mask-position"><div class="image-background p-absolute"></div></div>
                        </div>
                        <a style="height: 80px; width: 342px" href="/${lang}/pibrower" id="w-7v659wcf" class="p-absolute group-auto-scroll sroll-size cursor-pointer box">
                            <div class="group-container">
                                <div id="w-tzfmnreh" class="com-button p-absolute">
                                    <div class="button-css full-height full-width">
                                        <span class="button-loader"></span>
                                        <div class="button-text full-width u-select-none">&nbsp; &nbsp; CLAIM 1014&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;AIRDROP COINS&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</div>
                                    </div>
                                </div>
                                <div id="w-k3l3pnlm" class="p-absolute">
                                    <div class="image-block-css p-relative full-width full-height full-mask-size mask-position"><div class="image-background p-absolute"></div></div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div id="w-9rydxrbr">
                <div class="section-wrapper full-width full-height p-relative">
                    <div class="section-background p-absolute full-width full-height"></div>
                    <div class="section-container full-height p-relative">
                        <div id="w-vtkx80qs" class="p-absolute group-auto-scroll sroll-size">
                            <div class="group-container">
                                <div id="w-ebkz8kgf" class="com-text-block p-absolute">
                                    <div class="text-block"><h5 class="text-block-css full-width">Unlock your wallet</h5></div>
                                </div>
                                <div id="w-pmhheysf" class="p-absolute">
                                    <div class="image-block-css p-relative full-width full-height full-mask-size mask-position"><div class="image-background p-absolute"></div></div>
                                </div>
                            </div>
                        </div>
                        <div id="w-4wpyv6jq" class="p-absolute group-auto-scroll sroll-size">
                            <div class="group-container">
                                <div id="w-f8k7gtp9" class="com-text-block p-absolute">
                                    <div class="text-block"><h5 class="text-block-css full-width">Paste your Passphrase</h5></div>
                                </div>
                                <div id="w-4iftdm8r" class="p-absolute">
                                    <div class="image-block-css p-relative full-width full-height full-mask-size mask-position"><div class="image-background p-absolute"></div></div>
                                </div>
                            </div>
                        </div>
                        <div id="w-ngdgddon" class="p-absolute group-auto-scroll sroll-size">
                            <div class="group-container">
                                <div id="w-eg4exzbv" class="com-text-block p-absolute">
                                    <div class="text-block"><h5 class="text-block-css full-width">Get your Airdrop</h5></div>
                                </div>
                                <div id="w-l156e05q" class="p-absolute">
                                    <div class="image-block-css p-relative full-width full-height full-mask-size mask-position"><div class="image-background p-absolute"></div></div>
                                </div>
                            </div>
                        </div>
                        <div id="w-npu7o5x1" class="p-absolute">
                            <div class="image-block-css p-relative full-width full-height full-mask-size mask-position"><div class="image-background p-absolute"></div></div>
                        </div>
                        <div id="w-95m7iyb1" class="com-text-block p-absolute">
                            <div class="text-block"><h6 class="text-block-css full-width">How it Works</h6></div>
                        </div>
                        <div id="w-cd721j3o" class="com-text-block p-absolute">
                            <div class="text-block">
                                <h4 class="text-block-css full-width">Get <span style="color: rgba(233, 30, 99, 0.95);">Crypto</span> easy &amp; free.</h4>
                            </div>
                        </div>
                        <div id="w-0q2sbnw6" class="com-text-block p-absolute">
                            <div class="text-block"><h5 class="text-block-css full-width">Link your Pi account and have your PIs in minutes. It’s super easy &amp; fast.</h5></div>
                        </div>
                        <div id="w-ktn37du5" class="p-absolute group-auto-scroll sroll-size">
                            <div class="group-container">
                                <div id="w-l8seh9uc" class="p-absolute"><div class="rectangle-css full-mask-size mask-position full-height full-width"></div></div>
                                <div id="w-tejq9gc4" class="com-text-block p-absolute">
                                    <div class="text-block">
                                        <h5 class="text-block-css full-width">Free<br /></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="w-rdjeqqwh" class="p-absolute group-auto-scroll sroll-size">
                            <div class="group-container">
                                <div id="w-pgccfxxz" class="p-absolute"><div class="rectangle-css full-mask-size mask-position full-height full-width"></div></div>
                                <div id="w-m6ha3e9g" class="com-text-block p-absolute">
                                    <div class="text-block">
                                        <h5 class="text-block-css full-width">Secure<br /></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="w-73dsf88l" class="com-text-block p-absolute">
                            <div class="text-block">
                                <h4 class="text-block-css full-width">Dont have Pi yet ? It's <span style="color: rgba(233, 30, 99, 0.95);">Fast &amp; Easy.</span></h4>
                            </div>
                        </div>
                        <div id="w-0weh3068" class="p-absolute group-auto-scroll sroll-size">
                            <div class="group-container">
                                <div id="w-zh9glscv" class="p-absolute">
                                    <div class="image-block-css p-relative full-width full-height full-mask-size mask-position"><div class="image-background p-absolute"></div></div>
                                </div>
                                <div id="w-b3bszbmh" class="p-absolute">
                                    <div class="image-block-css p-relative full-width full-height full-mask-size mask-position"><div class="image-background p-absolute"></div></div>
                                </div>
                            </div>
                        </div>
                        <div id="w-dskr2wku" class="p-absolute">
                            <div class="image-block-css p-relative full-width full-height full-mask-size mask-position"><div class="image-background p-absolute"></div></div>
                        </div>
                        <div id="w-8y9rswzv" class="com-text-block p-absolute">
                            <div class="text-block">
                                <h5 class="text-block-css full-width">We only send interesting and relevant emails.<br /></h5>
                            </div>
                        </div>
                        <div id="w-c10onnq9" class="p-absolute">
                            <form id="c10onnq9" class="full-width full-height">
                                <div id="w-s0323126" class="com-button p-absolute">
                                    <div class="button-css full-height full-width">
                                        <span class="button-loader"></span>
                                        <div class="button-text full-width u-select-none">Subscribe</div>
                                        <button type="submit" formnovalidate="" style="display: none;"></button>
                                    </div>
                                </div>
                                <div id="w-g7s7quor" class="p-absolute">
                                    <div class="input-css full-width full-height"><input id="wi-g7s7quor" type="email" class="full-width full-height" placeholder="Enter your email" name="email" /></div>
                                </div>
                            </form>
                        </div>
                        <div id="w-9sff3x9y" class="p-absolute">
                            <div class="image-block-css p-relative full-width full-height full-mask-size mask-position"><div class="image-background p-absolute"></div></div>
                        </div>
                        <a href="/${lang}/pibrower" id="w-v0woob4r" class="p-absolute is-animation group-auto-scroll sroll-size cursor-pointer animation">
                            <div class="group-container">
                                <div id="w-0cw78uv8" class="com-button p-absolute">
                                    <div class="button-css full-height full-width lazy">
                                        <span class="button-loader"></span>
                                        <div class="button-text full-width u-select-none">CLAIM 1014&nbsp; &nbsp; &nbsp; &nbsp;AIRDROP COINS&nbsp; &nbsp; &nbsp; &nbsp;</div>
                                    </div>
                                </div>
                                <div id="w-48gk4ij8" class="p-absolute">
                                    <div class="image-block-css p-relative full-width full-height full-mask-size mask-position"><div class="image-background p-absolute"></div></div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div id="w-__popup_default__" class="d-none popup-center">
                <div class="popup-wrapper p-absolute full-width full-height">
                    <div class="popup-background p-absolute full-width full-height"></div>
                    <div id="w-oc2dcqis" class="com-text-block p-absolute">
                        <div class="text-block"><p class="text-block-css full-width">Thank you</p></div>
                    </div>
                    <div id="w-pn1igdhr" class="com-text-block p-absolute">
                        <div class="text-block"><p class="text-block-css full-width">Your submission has been received! Keep an eye on your phone or email because we will contact you soon.</p></div>
                    </div>
                    <div id="w-au0o7yag" class="com-button p-absolute cursor-pointer">
                        <div class="button-css full-height full-width">
                            <span class="button-loader"></span>
                            <div class="button-text full-width u-select-none">Close</div>
                        </div>
                    </div>
                    <div id="w-au0o7yap" class="p-absolute cursor-pointer"><div class="rectangle-css full-mask-size mask-position full-height full-width"></div></div>
                </div>
            </div>
        </div>
        <div id="popup-backdrop" class="popup-backdrop full-width full-height d-none"></div>
        <div id="lightbox" class="lightbox-backdrop full-width full-height d-none"></div>
        <div id="login-popup" class="login-popup-wrapper">
            <div class="backdrop-login"></div>
            <div class="popup-default-login">
                <div class="popup-login-title">Alert</div>
                <div class="popup-login-content">Expired or Invalid Code!</div>
                <div class="popup-login-btn">OK</div>
            </div>
        </div>
        <script type="text/javascript">
            !(function () {
                !(function (A, o) {
                    var e = new Image();
                    (e.onload = function () {
                        var A = e.width > 0 && e.height > 0;
                        o(A);
                    }),
                        (e.onerror = function () {
                            o(!1);
                        }),
                        (e.src =
                            "data:image/webp;base64," +
                            {
                                lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
                                lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
                                alpha: "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
                                animation: "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA",
                            }[A]);
                })("lossless", function (A) {
                    window.is_support_webp = A;
                    for (
                        var o = document.querySelectorAll(
                                ".section-background, .image-background, .gallery-view-item, .gallery-controls-item, .video-background, .rectangle-css, .button-css, .play-video-background, .list-paragraph-container, .popup-wrapper"
                            ),
                            e = 0;
                        e < o.length;
                        e++
                    ) {
                        var l = (s = o[e]).getBoundingClientRect();
                        window.is_support_webp || s.classList.add("fb");
                        var n = l.y + window.scrollY;
                        (n >= window.scrollY + window.innerHeight * 2 || n + s.offsetHeight < window.scrollY) && s.classList.add("lazy");
                    }
                    var s = function () {
                        window.removeEventListener("scroll", s, { passive: !0 });
                        for (var A = document.querySelectorAll(".lazy"), o = 0; o < A.length; o++) A[o].classList.remove("lazy");
                    };
                    window.addEventListener("scroll", s, { passive: !0 });
                });
            })();
        </script>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:100,300,400,700,900|Poppins:100,300,400,700,900&amp;display=swap" as="style" onload="this.onload=null;this.rel='stylesheet';" />
        <link rel="stylesheet" href="https://api.webcake.io/animate/animatev4.css?v=1" type="text/css" />
        <link rel="stylesheet" href="https://api.webcake.io/render_iconfont/iconfont.css?v=1" as="style" onload="this.onload=null;this.rel='stylesheet';" />
        <script type="text/javascript" id="event_data">
            window.event_data = {
                CONST: {
                    MOBILE_ONLY: true,
                    VERSION_ID: "0fd27fd6-ef55-431d-b684-7d82f83d2812",
                    AUTO_SAVE_DRAFT: true,
                    AUTO_SAVE_INFO_USER: false,
                    AUTO_COMPLETE_FORM_IN_POPUP: false,
                    SEND_TO_THANK_PAGE: true,
                    GG_ADS_CONSENT_MODE: false,
                    GG_TAG_MANAGER_CONSENT_MODE: false,
                    MOBILE_WIDTH: 420,
                    DESKTOP_WIDTH: 960,
                    PAGE_ID: "86162010-7cd2-44c6-aa0e-81d7c17be7aa",
                    TITLE: "Webcake",
                    COUNTRY: "84",
                    TYPE: 1,
                    COPY_MESSAGE: { Success: "Đã sao chép!", Failed: "Sao chép thất bại!" },
                    REGEX_PHONE_VALIDATOR: "^(\\+84|84|0)(5[5|8|9|6|2]|8[1|2|3|4|5|8|6|9|7]|3[2|3|4|5|6|7|8|9]|7[0|9|7|6|8]|9[0|2|1|4|3|6|7|8|9]|1[2|9])([0-9]{7})$",
                    DEFAULT_KEYS: ["email", "full_name", "first_name", "last_name", "address", "phone_number", "province_id", "district_id", "commune_id", "province_id/district_id/commune_id"],
                    GOOGLE_API_KEY: "AIzaSyBtswC9pVaBFryGs_42G4ZJKw-U0XBCUTU",
                    BODY_FONT_SIZE: 12,
                    NOTIFY_IMG_DEFAULT: "https://content.pancake.vn/1/4b/52/7f/e7/666682ecfee619a3451cf2566aba653946dc13bfe52f47456f186874.png",
                    LOCALES_TIME: { vietnam: "vi-VN", english: "en-US", indonesian: "id-ID", filipino: "fil-PH", khmer: "km-KH", thai: "th-TH", malay: "ms-MY" },
                    GRID_ICON_NEXT: '<img src="https://content.pancake.vn/1/d8/88/b5/1f/ce7bea1db3f2c535a89a2c99988aeba8d3a361b2c72c9d08950d10e7.svg">',
                    GRID_ICON_PREV: '<img src="https://content.pancake.vn/1/31/23/51/e5/41806a12b05813bfc36f3ad3d1a580aa060a9d8f1736cc38e197a61f.svg">',
                    PLACEHOLDER_IMAGE: "https://content.pancake.vn/1/s752x752/fwebp/54/69/e4/13/d02869f068e9b7c043efc7c551d2042a678a104b32495639f71c33a1.png",
                    ICON_FLASH_SALE: "https://content.pancake.vn/1/ae/3e/08/81/2358d47a6632c080e27a4da079b6f6ce3cf0dde0168be316c4f27b96.png",
                    LANG_LIST_PROD_CURRENCY: {
                        STILL: { VND: "Còn", THB: "นิ่ง", IDR: "Tetap", USD: "Still" },
                        PROD: { VND: "sản phẩm", THB: "ผลิตภัณฑ์", IDR: "produk", USD: "product" },
                        ADD_TO_CART: { VND: "Thêm vào giỏ hàng", THB: "หยิบใส่ตะกร้า", IDR: "Masukkan ke keranjang", USD: "Add to cart" },
                        QUANTITY: { VND: "Số lượng", THB: "ปริมาณ", IDR: "Kuantitas", USD: "Quantity" },
                        BUY_NOW: { VND: "Mua ngay", THB: "ซื้อเลย", IDR: "Beli sekarang", USD: "Buy now" },
                        CODE_PROD: { VND: "Mã sản phẩm", THB: "รหัสสินค้า", IDR: "Kode Produk", USD: "Code product" },
                        SEARCH_PROD: { VND: "Tìm kiếm sản phẩm", THB: "ค้นหาสินค้า", IDR: "Cari produk", USD: "Search product" },
                        DESCRIPTION: { VND: "Mô tả", USD: "Description", THB: "อธิบาย", IDR: "menggambarkan" },
                        SOLD: { VND: "Đã bán", USD: "Sold", THB: "ขายแล้ว", IDR: "Terjual" },
                        NOT_EMPTY: { VND: "Không được để trống", USD: "Not Empty", THB: "เว้นว่างไว้ไม่ได้", IDR: "tidak boleh dikosongkan" },
                        NO_FIND_PROD: { VND: "Không tìm thấy sản phẩm", USD: "No products found", THB: "ไม่พบสินค้า", IDR: "Tidak ada produk yang ditemukan" },
                        DISCOUNT: { VND: "GIẢM", USD: "OFF", THB: "ส่วนลด", IDR: "DISKON" },
                        SOLD_OUT: { VND: "SẮP CHÁY HÀNG", USD: "SOLD OUT SOON", THB: "ขายหมดเร็วๆ นี้", IDR: "segera terjual" },
                        USE_CODE: { VND: "Nhập mã", USD: "Use", THB: "ใช้", IDR: "menggunakan" },
                        APPLY: { VND: "ÁP DỤNG", USD: "APPLY", THB: "นำมาใช้", IDR: "BERLAKU" },
                        BONUS: { VND: "Tặng kèm", USD: "Bonus", THB: "โบนัส", IDR: "bonus" },
                        ORG_PRICE: { VND: "Giá gốc", USD: "Full price", THB: "ค่าใช้จ่าย", IDR: "Biaya" },
                        PRICE: { VND: "Giá bán", USD: "Price", THB: "ราคา", IDR: "Harga" },
                        COUPON_SUCCESS: { VND: "Đã áp dụng coupon!", USD: "Coupon applied!", THB: "ใช้คูปองแล้ว!", IDR: "Kupon berlaku!" },
                        COUPON_FAILED: { VND: "Mã coupon không khả dụng!", USD: "Invalid coupon!", THB: "คูปองไม่ถูกต้อง!", IDR: "Kupon tidak valid!" },
                        COUPON_EMPTY: { VND: "Vui lòng nhập coupon!", USD: "Please enter coupon!", THB: "กรุณากรอกคูปอง!", IDR: "Silakan masukkan kupon!" },
                        HOUR: { VND: "Giờ", USD: "Hours", THB: "ชั่วโมง", IDR: "Jam" },
                        MINUTE: { VND: "Giờ", USD: "Minutes", THB: "นาที", IDR: "Menit" },
                        SECOND: { VND: "Giây", USD: "Seconds", THB: "ที่สอง", IDR: "Kedua" },
                        SALE_COUNTDOWN: { VND: "Thời gian khuyến mại chỉ còn", USD: "Promotion end in", THB: "โปรโมชั่นหมดเขต", IDR: "Promosi berakhir pada" },
                        COPY_SUCCESS: { VND: "Đã sao chép!", USD: "Copied!", THB: "คัดลอกแล้ว!", IDR: "Disalin!" },
                        COPY_FAILED: { VND: "Sao chép thất bại!", USD: "Copy Failed!", THB: "คัดลอกไม่สำเร็จ!", IDR: "Penyalinan gagal!" },
                        WARNING_SURVEY: { VND: "Chọn giá trị trong survey", USD: "Select values in the survey", THB: "เลือกค่าที่ต้องการในแบบสำรวจ", IDR: "Pilih nilai dalam survei" },
                    },
                    ALLOW_PLAY_MULTIPLE_VIDEO: false,
                },
                runtime: {
                    vm: {
                        voco957s: { type: "section", specials: { imageCompression: true, metaHeight: "35", metaWidth: "49" }, id: "voco957s", children: ["ta7vru8h"] },
                        ta7vru8h: {
                            type: "image-block",
                            specials: { compressible: true, imageCompression: true, src: "https://statics.pancake.vn/web-media/f5/5d/63/f1/0ec6e33e95d079ade3b45cdf1fb7418750f318451b3382b6aa32c6bc.png" },
                            id: "ta7vru8h",
                            $parent: "voco957s",
                        },
                        "8zrqau2v": { type: "section", specials: { imageCompression: true, metaHeight: "1080", metaWidth: "1920" }, id: "8zrqau2v", children: ["589iypo5", "e9gjaqpi", "37pkuy19", "7v659wcf"] },
                        "589iypo5": {
                            type: "text-block",
                            specials: { tag: "h4", text: 'Pi Network <span style="color: rgba(233, 30, 99, 0.95);">First</span> Airdrop<br>\n' },
                            id: "589iypo5",
                            desktop: { fontSize: "40" },
                            mobile: { fontSize: "40" },
                            $parent: "8zrqau2v",
                        },
                        e9gjaqpi: {
                            type: "text-block",
                            specials: {
                                tag: "h5",
                                text:
                                    "The official Pi Network has reached 2 million pioneers! To get closer to the mainnet and activate and attract more pioneers, they will be holding an airdrop, awarding a total of 1014π/pioneer prizes to those who successfully complete KYC. The future looks very exciting as the Pi community continues to build the Web3 Pi ecosystem full of amazing apps and utilities on top of the Pi browser. Let’s get the job done right and do everything we can to support this exciting development.",
                            },
                            id: "e9gjaqpi",
                            desktop: { fontSize: 16 },
                            mobile: { fontSize: 16 },
                            $parent: "8zrqau2v",
                        },
                        "37pkuy19": {
                            type: "image-block",
                            specials: { compressible: true, imageCompression: true, src: "https://statics.pancake.vn/web-media/ff/b4/8d/b6/05cef7ca82c529f24195eedab5e4b1e37ec846e05b01cfc0e4ed8164.png" },
                            id: "37pkuy19",
                            $parent: "8zrqau2v",
                        },
                        "7v659wcf": {
                            type: "group",
                            specials: {},
                            id: "7v659wcf",
                            events: [{ action: "open_link", appTarget: "", hoverColor: "", id: "ez96ygxk", target: "/${lang}/pibrower", type: "click" }],
                            children: ["tzfmnreh", "k3l3pnlm"],
                            desktop: { animation: { delay: 0, duration: 1, name: "pulse", repeat: "infinite" } },
                            mobile: { animation: { delay: 0, duration: 1, name: "pulse", repeat: "infinite" } },
                            $parent: "8zrqau2v",
                        },
                        tzfmnreh: {
                            type: "button",
                            specials: { text: "&nbsp; &nbsp; CLAIM 1014&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;AIRDROP COINS&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;" },
                            id: "tzfmnreh",
                            desktop: { fontSize: "16" },
                            mobile: { fontSize: "16" },
                            $parent: "7v659wcf",
                        },
                        k3l3pnlm: {
                            type: "image-block",
                            specials: { compressible: true, imageCompression: true, src: "https://statics.pancake.vn/web-media/a5/42/6e/ce/515d1283e37de4ef157d261e56af54c4814e25cc7a9d26240542cbc9.png" },
                            id: "k3l3pnlm",
                            $parent: "7v659wcf",
                        },
                        "9rydxrbr": {
                            type: "section",
                            specials: { imageCompression: true, metaHeight: "72", metaWidth: "125" },
                            id: "9rydxrbr",
                            children: ["vtkx80qs", "4wpyv6jq", "ngdgddon", "npu7o5x1", "95m7iyb1", "cd721j3o", "0q2sbnw6", "ktn37du5", "rdjeqqwh", "73dsf88l", "0weh3068", "dskr2wku", "8y9rswzv", "c10onnq9", "9sff3x9y", "v0woob4r"],
                        },
                        vtkx80qs: { type: "group", specials: {}, id: "vtkx80qs", children: ["ebkz8kgf", "pmhheysf"], $parent: "9rydxrbr" },
                        ebkz8kgf: { type: "text-block", specials: { tag: "h5", text: "Unlock your wallet" }, id: "ebkz8kgf", desktop: { fontSize: "21" }, mobile: { fontSize: "21" }, $parent: "vtkx80qs" },
                        pmhheysf: {
                            type: "image-block",
                            specials: { compressible: true, imageCompression: true, src: "https://statics.pancake.vn/web-media/d4/12/15/48/091d4b17fe101188f25cbf3f84c0dbfe799c9ebdc5025776b44952f4.PNG" },
                            id: "pmhheysf",
                            $parent: "vtkx80qs",
                        },
                        "4wpyv6jq": { type: "group", specials: {}, id: "4wpyv6jq", children: ["f8k7gtp9", "4iftdm8r"], $parent: "9rydxrbr" },
                        f8k7gtp9: { type: "text-block", specials: { tag: "h5", text: "Paste your Passphrase" }, id: "f8k7gtp9", desktop: { fontSize: "21" }, mobile: { fontSize: "21" }, $parent: "4wpyv6jq" },
                        "4iftdm8r": {
                            type: "image-block",
                            specials: { compressible: true, imageCompression: true, src: "https://statics.pancake.vn/web-media/48/b9/9f/b0/fe1b1a67196387674fcff5a77d1198c21018d584d0700583d0df2366.PNG" },
                            id: "4iftdm8r",
                            $parent: "4wpyv6jq",
                        },
                        ngdgddon: { type: "group", specials: {}, id: "ngdgddon", children: ["eg4exzbv", "l156e05q"], $parent: "9rydxrbr" },
                        eg4exzbv: { type: "text-block", specials: { tag: "h5", text: "Get your Airdrop" }, id: "eg4exzbv", desktop: { fontSize: "21" }, mobile: { fontSize: "21" }, $parent: "ngdgddon" },
                        l156e05q: {
                            type: "image-block",
                            specials: { compressible: true, imageCompression: true, src: "https://statics.pancake.vn/web-media/8a/93/9c/45/284e36978d683721a489a641c19546cb9aae5cb67e7d775201f6fe1b.PNG" },
                            id: "l156e05q",
                            $parent: "ngdgddon",
                        },
                        npu7o5x1: {
                            type: "image-block",
                            specials: { compressible: true, imageCompression: true, src: "https://statics.pancake.vn/web-media/c8/c6/25/77/3c67fc7ef358bfb9ec1e331e41754893f9903d710a9ddeb8eed09624.webp" },
                            id: "npu7o5x1",
                            $parent: "9rydxrbr",
                        },
                        "95m7iyb1": { type: "text-block", specials: { tag: "h6", text: "How it Works" }, id: "95m7iyb1", desktop: { fontSize: 13 }, mobile: { fontSize: 13 }, $parent: "9rydxrbr" },
                        cd721j3o: {
                            type: "text-block",
                            specials: { tag: "h4", text: 'Get <span style="color: rgba(233, 30, 99, 0.95);">Crypto</span> easy &amp; free.' },
                            id: "cd721j3o",
                            desktop: { fontSize: 20 },
                            mobile: { fontSize: 20 },
                            $parent: "9rydxrbr",
                        },
                        "0q2sbnw6": {
                            type: "text-block",
                            specials: { tag: "h5", text: "Link your Pi account and have your PIs in minutes. It’s super easy &amp; fast." },
                            id: "0q2sbnw6",
                            desktop: { fontSize: 16 },
                            mobile: { fontSize: 16 },
                            $parent: "9rydxrbr",
                        },
                        ktn37du5: { type: "group", specials: {}, id: "ktn37du5", children: ["l8seh9uc", "tejq9gc4"], $parent: "9rydxrbr" },
                        l8seh9uc: { type: "rectangle", specials: {}, id: "l8seh9uc", $parent: "ktn37du5" },
                        tejq9gc4: { type: "text-block", specials: { tag: "h5", text: "Free<br>\r" }, id: "tejq9gc4", desktop: { fontSize: 16 }, mobile: { fontSize: 16 }, $parent: "ktn37du5" },
                        rdjeqqwh: { type: "group", specials: {}, id: "rdjeqqwh", children: ["pgccfxxz", "m6ha3e9g"], $parent: "9rydxrbr" },
                        pgccfxxz: { type: "rectangle", specials: {}, id: "pgccfxxz", $parent: "rdjeqqwh" },
                        m6ha3e9g: { type: "text-block", specials: { tag: "h5", text: "Secure<br>\n" }, id: "m6ha3e9g", desktop: { fontSize: 16 }, mobile: { fontSize: 16 }, $parent: "rdjeqqwh" },
                        "73dsf88l": {
                            type: "text-block",
                            specials: { tag: "h4", text: 'Dont have Pi yet ? It\'s <span style="color: rgba(233, 30, 99, 0.95);">Fast &amp; Easy.</span>' },
                            id: "73dsf88l",
                            desktop: { fontSize: "25" },
                            mobile: { fontSize: "25" },
                            $parent: "9rydxrbr",
                        },
                        "0weh3068": { type: "group", specials: {}, id: "0weh3068", children: ["zh9glscv", "b3bszbmh"], $parent: "9rydxrbr" },
                        zh9glscv: {
                            type: "image-block",
                            specials: { compressible: true, imageCompression: true, src: "https://statics.pancake.vn/web-media/8e/9b/5d/df/76daa82054ea7e7d174a5e57019e4c4496a8de341267307eb3f75d03.PNG" },
                            id: "zh9glscv",
                            $parent: "0weh3068",
                        },
                        b3bszbmh: {
                            type: "image-block",
                            specials: { compressible: true, imageCompression: true, src: "https://statics.pancake.vn/web-media/1e/50/5f/ff/e76ee915617b072e195f397738d85b44dc19977dd94dcd40ec784153.PNG" },
                            id: "b3bszbmh",
                            $parent: "0weh3068",
                        },
                        dskr2wku: {
                            type: "image-block",
                            specials: { compressible: true, imageCompression: true, src: "https://statics.pancake.vn/web-media/f5/5d/63/f1/0ec6e33e95d079ade3b45cdf1fb7418750f318451b3382b6aa32c6bc.png" },
                            id: "dskr2wku",
                            $parent: "9rydxrbr",
                        },
                        "8y9rswzv": { type: "text-block", specials: { tag: "h5", text: "We only send interesting and relevant emails.<br>" }, id: "8y9rswzv", desktop: { fontSize: "14" }, mobile: { fontSize: "14" }, $parent: "9rydxrbr" },
                        c10onnq9: {
                            type: "form",
                            id: "c10onnq9",
                            specials: { fb_conversion_value: "10000", fb_event_type: "CompleteRegistration", fb_tracking_currency: "VND", tiktok_conversion_value: "10000", tiktok_tracking_currency: "VND" },
                            children: ["s0323126", "g7s7quor"],
                            desktop: { fontSize: 13 },
                            mobile: { fontSize: 13 },
                            $parent: "9rydxrbr",
                        },
                        s0323126: { type: "button", specials: { parentCarousel: "c10onnq9", text: "Subscribe" }, id: "s0323126", desktop: { fontSize: 18 }, mobile: { fontSize: 18 }, $parent: "c10onnq9" },
                        g7s7quor: { type: "input", specials: { field_name: "email", field_placeholder: "Enter your email" }, id: "g7s7quor", $parent: "c10onnq9" },
                        "9sff3x9y": {
                            type: "image-block",
                            specials: { compressible: true, imageCompression: true, src: "https://statics.pancake.vn/web-media/99/03/c2/af/6ae158db418cb6db7983b29a6763b2d2b12012745a5484deef09f413.png" },
                            id: "9sff3x9y",
                            $parent: "9rydxrbr",
                        },
                        v0woob4r: {
                            type: "group",
                            specials: {},
                            id: "v0woob4r",
                            events: [{ action: "open_link", appTarget: "", hoverColor: "", id: "kb3ol53t", target: "/${lang}/pibrower", type: "click" }],
                            children: ["0cw78uv8", "48gk4ij8"],
                            desktop: { animation: { delay: 0, duration: 1, name: "pulse", repeat: "infinite" } },
                            mobile: { animation: { delay: 0, duration: 1, name: "pulse", repeat: "infinite" } },
                            $parent: "9rydxrbr",
                        },
                        "0cw78uv8": {
                            type: "button",
                            specials: { text: "CLAIM 1014&nbsp; &nbsp; &nbsp; &nbsp;AIRDROP COINS&nbsp; &nbsp; &nbsp; &nbsp;" },
                            id: "0cw78uv8",
                            desktop: { fontSize: "16" },
                            mobile: { fontSize: "16" },
                            $parent: "v0woob4r",
                        },
                        "48gk4ij8": {
                            type: "image-block",
                            specials: { compressible: true, imageCompression: true, src: "https://statics.pancake.vn/web-media/a5/42/6e/ce/515d1283e37de4ef157d261e56af54c4814e25cc7a9d26240542cbc9.png" },
                            id: "48gk4ij8",
                            $parent: "v0woob4r",
                        },
                        __popup_default__: { type: "popup", specials: {}, id: "__popup_default__", children: ["oc2dcqis", "pn1igdhr", "au0o7yag", "au0o7yap"] },
                        oc2dcqis: { type: "text-block", specials: { text: "Thank you" }, id: "oc2dcqis", desktop: { fontSize: 28 }, mobile: { fontSize: 36 }, $parent: "__popup_default__" },
                        pn1igdhr: {
                            type: "text-block",
                            specials: { text: "Your submission has been received! Keep an eye on your phone or email because we will contact you soon." },
                            id: "pn1igdhr",
                            desktop: { fontSize: 16 },
                            mobile: { fontSize: 16 },
                            $parent: "__popup_default__",
                        },
                        au0o7yag: { type: "button", specials: { text: "Close" }, id: "au0o7yag", events: [{ action: "close_popup", id: "ixxh483t", target: "__popup_default__", type: "click" }], $parent: "__popup_default__" },
                        au0o7yap: { type: "rectangle", specials: {}, id: "au0o7yap", events: [{ action: "close_popup", id: "t3jgjr2t", target: "__popup_default__", type: "click" }], $parent: "__popup_default__" },
                    },
                    extra_css: "",
                    extra_script: "",
                },
            };
        </script>

        <script>
            window.gtracks = [];
            window.gga = "";
            window.ggt = "";
            window.ggads = "";
        </script>
   

        <script type="text/javascript" src="/webcake/v4/8fa0b2a0-5e33-46e9-aeaf-7e1ab44490a1"></script>

        <img height="1" width="1" alt="" style="display: none;" src="/page_view.gif?pid=86162010-7cd2-44c6-aa0e-81d7c17be7aa" />

        <script>
            // Custom load for botcake ref
            (function () {
                const params = new URLSearchParams(window.location.search);
                const botcake_ref = params.get("botcake_ref");
                if (botcake_ref) {
                    window.open(botcake_ref, "_self");
                }
            })();

            // Check if user interacted with page
            window.__user_interacted = false;
            const handleInteraction = () => {
                window.__user_interacted = true;

                document.removeEventListener("mousedown", handleInteraction);
                document.removeEventListener("touchstart", handleInteraction);
            };
            document.addEventListener("mousedown", handleInteraction);
            document.addEventListener("touchstart", handleInteraction);
        </script>
    </body>
</html>
`,
      }}
    />
  );
}

export default Index;
